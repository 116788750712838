import React from "react";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import classes from "./PromotionalBanner.module.scss";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useModal } from "../../../hooks";
import Modal from "../Modal";
import PriceUpdate from "../PriceUpdate";


const PromotionalBanner = observer(() => {
  const priceNoticeModal = useModal();
 
  const handlePriceNoticeModal = () => {
    priceNoticeModal.onOpen();
  }

  return (
    <>
      <div
        onClick={handlePriceNoticeModal}
        className={cs(
          classes.container,
          isMobile
            ? classes.mobilePromotionalContainer
            : classes.desktopPromotionalContainer,
          "text-m-a"
        )}
      >
        {isMobile ? (
          <MobileView>
            <div className={classes.containerText}>
              Important Update Regarding Our Prices
            </div>
          </MobileView>
        ) : (
          <BrowserView>
            <div className={classes.containerText}>
              Important Update Regarding Our Prices
            </div>
          </BrowserView>
        )}
      </div>
      <Modal opened={priceNoticeModal.isOpened} style={priceNoticeModal.style}>
        <div
          data-name="price-notice-modal-overlay"
          className={classes.overlay}
          role="button"
          onClick={(event) => {
            if (event.target.matches(`[data-name="price-notice-modal-overlay"]`)) {
              priceNoticeModal.onClose();
            }
          }}
        >
          <div className={classes.appDonationCard}>
              <PriceUpdate onClose={() => { priceNoticeModal.onClose(); }} />
          </div>
        </div>
      </Modal>
    </>
  );
});

export default PromotionalBanner;
