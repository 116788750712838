import { observer } from "mobx-react-lite";
import React from "react";
import emailIcon from "../../../assets/images/email.png";
import cs from "classnames";
import classes from "./VerifyEmail.module.scss";
import PropTypes from "prop-types";
import Button from "../../atoms/Button";
import { useContext } from "react";
import { StoreContext } from "../../../contexts";
import ProgressBar from "../../atoms/ProgressBar";
import { useQuery } from "../../../hooks";
import logo from "../../../assets/images/logotype-maor.png";

const VerifyEmail = observer(() => {
  const { authorization } = useContext(StoreContext);

  const query = useQuery();

  let userEmail;

  if (authorization.isAuthenticated) {
    userEmail = authorization.user.email;
  } else {
    userEmail = query.get("email");
  }

  const openMail = () => {
    if (userEmail) {
      const emailDomain = userEmail.split("@")[1].toLowerCase();

      switch (emailDomain) {
        case "gmail.com":
          window.location.href = "https://mail.google.com/";
          break;
        case "yahoo.com":
          window.location.href = "https://mail.yahoo.com/";
          break;
        case "hotmail.com":
          window.location.href = "https://outlook.live.com/owa/";
          break;
        case "aol.com":
          window.location.href = "https://mail.aol.com/";
          break;
        case "outlook.com":
          window.location.href = "https://outlook.live.com/owa/";
          break;
        default:
          window.location.href = "https://mail.google.com/";
      }
    }
  };

  return (
    <div className="bg-gold-gradient--horizontal c-grey-dark text-m">
      <div className="wrapper-s pt-05 d-flex fd-column ai-center minh-100vh">
        <div>
          <div className={cs(classes.logoContainer)}>
            <img alt="maor" className={classes.image} src={logo} />
          </div>

          {/* <Logotype url={`${APPLICATION_ROUTES.VERIFY_EMAIL}?email=${userEmail}`} /> */}
        </div>
        <div className="mt-08 w-100p" style={{ maxWidth: "300px" }}>
          <ProgressBar current="1" length="1" />
        </div>
        <div className="mt-10 w-100p mb-05">
          <div className="d-flex fd-column ai-center jc-center w-100p">
            <h2 className="heading-m-a ta-center c-blue-dark mb-03">Verify your email address</h2>

            <div className="jutify-center mt-10">
              <img src={emailIcon} alt="emailIcon" />
            </div>

            <div className="ta-center mt-10">
              <p>
                A confirmation email has been sent to <br /> <b> {userEmail} </b>{" "}
              </p>

              <p className="mt-06">
                Click on the confirmation link in the email to <br /> activate your account. if you do not see the
                email, <br /> please check your spam folder.
              </p>
            </div>

            <div className={cs(classes.checkEmailButton, "mt-10")}>
              <Button borderRadius="8" size="m" stretched onClick={openMail}>
                Check your Email
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const inputPropTypes = PropTypes.shape({
  error: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

VerifyEmail.propTypes = {
  email: inputPropTypes,
};

export default VerifyEmail;
