const ACTIONS = {
  GET_VIDEO: "GetVideo",
  GET_DAILY_REBBE_VIDEO: "GetDailyRebbeVideo",
  GET_MY_MAOR: "GetMyMaor",
  GET_ILLUMINATE: "GetIlluminate",
  GET_RELATED: "GetRelated",
  GET_COMBINED: "GetCombined",
  ALL_SECTIONS: "AllSections",
  GET_UPCOMING_HOLIDAY: "GetUpcomingHoliday",
  SAVE_VIDEO_QUESTION_OPTION: "saveVideoQuestionOption",
  FILTER_DATA: {
    CATEGORIES: "categories",
    TAGS: "tags",
    HOLIDAYS: "holidays",
  },
  SEARCHING_OPTIONS: "SearchingOptions",
  SAVE_SEARCH_RESULT: "SaveSearchResult",
};

export { ACTIONS };
