import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Loader from "../../atoms/Loader";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import Cart from "./components/Cart";
import Submit from "./components/Submit";
import Payment from "./components/Payment";
import classes from "./Checkout.module.scss";
import { useQuery } from "../../../hooks";
import useForm from "../PartnersOrder/useForm";
import { useParams } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";

const Checkout = observer(() => {
  const { authorization, cart, checkout } = useContext(StoreContext);
  const query = useQuery();
  let isLoading = authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`];
  const [isCartAddLoadingLocal, setIsCartAddLoadingLocal] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const pledgeDonation = query.get("page") === "pledge" ? true : false;
  const error = query.get("error");
  const hasErrorCreateTransaction =
    checkout[`error${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`];
  const getCartError = cart[`error${ACTIONS.CART.GET}`];

  // const projectType = query.get("projectType");
  // const billingCycle = query.get("billingCycle");

  const { plan, interval, partnership } = useParams();

  let form;
  if (partnership) {
    form = useForm();
  }

  // console.log(plan, interval, partnership)

  useEffect(() => {
    // authorization.resignIn();

    if (partnership && authorization.isAuthenticated) {
      form.onSubmit({
        onSuccess: () => {
          setIsCartAddLoadingLocal(false);
        },
        projectType: plan,
        billingCycle: interval,
        prefilled: {
          firstName: authorization.user.first_name,
          lastName: authorization.user.last_name,
          email: authorization.user.email,
        },
      });
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  });

  return (
    <div
      className={cs(
        classes.container,
        pledgeDonation && classes.pledgeBackgroung
      )}
    >
      <div className={cs(classes.wrapper, "wrapper-xl")}>
        <nav className={classes.logotype}>
          {pledgeDonation ? (
            <Logotype type={LOGOTYPE_TYPES.MAOR_PLEDGE} size="l" />
          ) : (
            <Logotype
              type={LOGOTYPE_TYPES.PARTNERS}
              url={APPLICATION_ROUTES.PARTNERS_PACKAGES}
            />
          )}
        </nav>
        <main className={classes.card}>
          <div className={cs(classes.main, classes.changePadding)}>
            {isLoading ? (
              <div className={cs("mb-08")}>
                <Loader />
              </div>
            ) : (
              <>
                {partnership && isCartAddLoadingLocal ? (
                  <div className={cs("mb-08")}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <section className={classes.cart}>
                      <Cart />
                    </section>
                    {cart.isNotEmpty && (
                      <>
                        <section className={classes.payment}>
                          <Payment errorMessage={errorMessage} />
                        </section>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {cart.isNotEmpty && (
            <>
              <div
                className={cs(
                  classes.footer,
                  pledgeDonation && classes.backgroundWhite,
                  (errorMessage || getCartError || hasErrorCreateTransaction) &&
                    classes.errorBg
                )}
              >
                <Submit
                  pledgeDonation={pledgeDonation}
                  error={
                    errorMessage || getCartError || hasErrorCreateTransaction
                  }
                />
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
});

export default Checkout;
