import React from "react";
import styles from "./PriceUpdate.module.scss"
import Logotype from "../../atoms/Logotype";
import Icon from "../../atoms/Icon";
import PropTypes from "prop-types";

const PriceUpdate = ({ onClose = null }) => {
  return (
    <div className={styles.priceUpdate}>
      <div className={styles.content}>
        {onClose && (
            <div className={styles.closeContainer}>
                <button
                    className={styles.close}
                    onClick={onClose}
                    >
                    <Icon name="exit" size="l" />
                </button>
            </div>
        )}

        <div className={styles.logo}>
          <Logotype />
        </div>

        <h1 className={styles.title}>IMPORTANT UPDATE REGARDING OUR PRICES</h1>

        <div className={styles.letterContent}>
          <p className={styles.greeting}>Dear Chassidishe Family,</p>

          <p>
            After 10 incredible years, with Hashem&apos;s help and the zechus of being shluchim, connecting tens of thousands
            of children and adults with the Rebbe through the production of high-quality materials, we are writing to
            share an important update.
          </p>

          <p>
            To continue this avodas hakodesh and keep producing the content you rely on, we find it necessary to adjust
            our prices. This step is essential to ensure the sustainability of our work and enable us to remain a keili
            for sharing the Rebbe&apos;s lichtike messages with Klal Yisroel.
          </p>

          <p>
            We deeply appreciate your partnership and support in this holy mission and look forward to continuing to
            serve you and your families with chassidishe content that uplifts and inspires.
          </p>

          <p className={styles.signature}>
            Moshiach now!
            <br /><strong>- Your Partners at Maor</strong>
          </p>

          <div className={styles.divider}></div>

          <div className={styles.pricingSection}>
            <h2>Partnership:</h2>
            <ul>
              <li>Basic: $96/year (for current monthly subscribers: $10/month).</li>
              <li>Card Partner: $240/year.</li>
              <li>Silver: $240/year (for current monthly subscribers: $24/month).</li>
              <li>Silver + Card: $345/year.</li>
              <li>Gold prices will remain the same.</li>
            </ul>

            <h2>Birthday Celebration:</h2>
            <ul>
              <li>Announcement: $54.</li>
              <li>Announcement + Gift Package: $72.</li>
              <li>Dedications prices will remain the same.</li>
            </ul>

            <h2>One more important change:</h2>
            <ul>
              <li>The weekly new videos will also require a partnership to watch.</li>
              <li>We will offer a 14-day free trial.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

PriceUpdate.propTypes = {
    onClose: PropTypes.func,
};

export default PriceUpdate;