import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../contexts";
import { useEffect } from "react";
import { ACTIONS } from "../../../store";
import { Link } from "react-router-dom";
import {
  APPLICATION_ROUTES,
  PARTNERSHIP_STATUS,
  COOKIES,
  DONATION_STATUS,
} from "../../../constants";
import Loader from "../../atoms/Loader";
import classes from "./Partnership.module.scss";
import { PROJECTS } from "../../../constants";
import { Trans, useTranslation } from "react-i18next";
import { getPackageDetails } from "../../../utilities/helpers";
import Modal from "../../organisms/Modal";
import { useDateFormat, useModal } from "../../../hooks";
import Button, { COLORS } from "../../atoms/Button";
import cs from "classnames";
import PasswordInput from "../../atoms/PasswordInput";
import Cookies from "../../../utilities/cookies";

const BRONZE_PLAN_ID = "M-Basic";

const Partnership = observer(() => {
  const { t } = useTranslation();
  const isAutoSignin = Cookies.get({ name: COOKIES.AUTO_SIGNIN });
  const { isOpened, onClose, onOpen } = useModal();
  let { partnership, authorization, donation } = useContext(StoreContext);
  const [currentPlan, setCurrentPlan] = useState("");
  const [partnershipLastUpdated, setPartnershipLastUpdated] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  // const [pledgeExpirationDate, setPledgeExpirationDate] = useState("");
  const [partnershipExpiration, setPartnershipExpiration] = useState("");
  const [enableCancelation, setEnableCancelation] = useState(
    isAutoSignin == 1 ? true : false
  );
  const [password, setPassword] = useState("");
  const [cancelDonationPlan, setCancelDonationPlan] = useState("");
  const [donationPrice, setDonationPrice] = useState("");
  const [donationPlanInterval, setDonationPlanInterval] = useState("");
  const [donationId, setDonationId] = useState(null);
  const [donationActive, setDonationActive] = useState(false);

  const getAction = ACTIONS.PARTNERSHIP.GET_PARTNERSHIP;
  const getAllSusbsciptionAction = ACTIONS.DONATION.GET_ALL_SUBSCRIPTION;
  const cancel = ACTIONS.PARTNERSHIP.CANCEL;
  // const getDonationAction = ACTIONS.DONATION.GET_DONATION;
  const cancelDonationAction = ACTIONS.DONATION.CANCEL_DONATION;

  useEffect(() => {
    partnership[`${getAction}`]();
    donation[`${getAllSusbsciptionAction}`]();
    // donation[`${getDonationAction}`]();
  }, []);

  useEffect(() => {
    if (partnership.hasDetails) {
      setCurrentPlan(
        partnership.details["plan"].toLowerCase().replace("-", "_")
      );
      setPartnershipLastUpdated(partnership.details["updated_at"]);
      setPartnershipExpiration(partnership.details["next_payment_date"]);
      setExpirationDate(partnership.details["expiration_date"]);
    }

    if (donation.hasData) {
      donation.data.forEach((curObj) => {
        if (curObj.status === DONATION_STATUS.ACTIVE) {
          setDonationActive(true);
        }
      });
    }
  }, [partnership.details, donation.data]);

  const cancelDonation = (planPrice, interval, id) => {
    setDonationPrice(planPrice);
    setDonationPlanInterval(interval);
    setCancelDonationPlan(true);
    setDonationId(id);
    onOpen();
  };

  const cancelPartnership = () => {
    setCancelDonationPlan(false);
    onOpen();
  };

  const cancelPlan = () => {
    if (cancelDonationPlan) {
      enableCancelation ? handleDonationCancel() : "";
    } else {
      enableCancelation ? handlePartnershipCancel() : "";
    }
  };

  const handleDonationCancel = () => {
    if (authorization.isAuthenticated) {
      if (isAutoSignin == 1) {
        donation[`${cancelDonationAction}`]({ donationId });
        onClose();
      } else {
        authorization.autheticatePassword(
          authorization.user.email,
          password,
          () => {
            donation[`${cancelDonationAction}`]({ donationId });
            onClose();
          }
        );
      }
    }
  };

  const handlePartnershipCancel = () => {
    if (authorization.isAuthenticated) {
      if (isAutoSignin == 1) {
        partnership[`${cancel}`]();
        onClose();
      } else {
        authorization.autheticatePassword(
          authorization.user.email,
          password,
          () => {
            partnership[`${cancel}`]();
            onClose();
          }
        );
      }
    }
  };

  const planTitle = () => {
    if (currentPlan) {
      const packageDetails = getPackageDetails(currentPlan);
      const suffix =
        currentPlan == PROJECTS.PARTNERS.PACKAGES.BASIC_TIER.ID
          ? " Partner"
          : "";
      return t(packageDetails.TITLE_TRANSLATION_KEY) + suffix;
    } else {
      return "";
    }
  };

  const planName = () => {
    if (currentPlan) {
      const packageDetails = getPackageDetails(currentPlan);
      return t(packageDetails.NAME_TRANSLATION_KEY);
    } else {
      return "";
    }
  };

  const handlePasswordChange = (e) => {
    setEnableCancelation(e.target.value.length >= 2 ? true : false);
    setPassword(e.target.value);
  };

  const turnOnAutoRenewal = () => {
    partnership.turnOnAutoRenewal({
      onSuccess: () => {
        partnership[`${getAction}`]();
      },
    });
  };

  // const formatPledgeDate = (pledgeExpirationDate) => {
  //   let pledgeExpiration = pledgeExpirationDate;
  //   pledgeExpiration = pledgeExpiration.split(" ")[0].split("-");
  //   return `${pledgeExpiration[1]}/${pledgeExpiration[2]}/${pledgeExpiration[0]}`;
  // }

  console.log(partnership.details, "details");

  return (
    <>
      {!partnership[`isLoading${getAction}`] ? (
        <>
          {partnership.hasDetails ? (
            <>
              {partnership.details["status"] != PARTNERSHIP_STATUS.CANCELED ? (
                <>
                  {partnership.details["status"] !=
                    PARTNERSHIP_STATUS.EXPIRED &&
                  partnership.details["status"] != PARTNERSHIP_STATUS.FREE ? (
                    <>
                      <span className={classes.planName}>
                        You are a: <br /> <strong>{planTitle()}</strong>
                      </span>
                      {partnership.details["status"] ==
                        PARTNERSHIP_STATUS.PAUSED ||
                      partnership.details["status"] ==
                        PARTNERSHIP_STATUS.PAST_DUE ? (
                        <span className={classes.planUpgrade}>
                          Your partnership is past due, please{" "}
                          <Link
                            to={APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD}
                          >
                            click here
                          </Link>{" "}
                          to update your payment methods.
                        </span>
                      ) : (
                        <>
                          {partnership?.details?.trial_ends_at &&
                          partnership.details["plan"] == BRONZE_PLAN_ID ? (
                            ""
                          ) : (
                            <span className={classes.planUpgrade}>
                              <Link to={APPLICATION_ROUTES.PARTNERS_PACKAGES}>
                                {currentPlan ==
                                PROJECTS.PARTNERS.PACKAGES.GOLD_TIER.ID
                                  ? t("partnership.change")
                                  : t("partnership.upgrade")}
                              </Link>
                            </span>
                          )}
                          {partnership?.details?.trial_ends_at &&
                            partnership.details["plan"] == BRONZE_PLAN_ID && (
                              <span className={cs(classes.freeTailExpire)}>
                                <Trans
                                  i18nKey={"partnership.free_trial_end"}
                                  components={{
                                    date: useDateFormat(
                                      partnership?.details?.trial_ends_at
                                    ),
                                  }}
                                  t={t}
                                />
                              </span>
                            )}
                          {!partnership[`isLoading${cancel}`] ? (
                            <span
                              className={classes.planCancel}
                              onClick={() => {
                                // dropdown.close();
                                cancelPartnership();
                                // document.querySelector("#popover-root").innerHTML = "";
                                // document.body.setAttribute("data-cr-popover", "disabled");
                                // document.querySelector("[data-react-scrolllock]").remove();
                              }}
                            >
                              {partnership?.details?.trial_ends_at &&
                              partnership.details["plan"] == BRONZE_PLAN_ID
                                ? t("partnership.aro")
                                : t("partnership.aro")}
                            </span>
                          ) : (
                            <Loader />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {partnership.details["status"] ==
                      PARTNERSHIP_STATUS.FREE ? (
                        <div>
                          <p>
                            Your free access will expire on{" "}
                            {useDateFormat(expirationDate, "T")}.
                          </p>
                        </div>
                      ) : (
                        <>
                          <div>
                            <p>
                              Your {planName()} partnership expired on{" "}
                              {useDateFormat(expirationDate, "T")}.
                            </p>
                          </div>
                          <div className={classes.planUpgrade}>
                            <Link to={APPLICATION_ROUTES.PARTNERS_PACKAGES}>
                              Renew Now
                            </Link>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <span className={classes.planName}>
                    You are a: <br /> <strong>{planTitle()}</strong>
                  </span>
                  {Date.now() > new Date(expirationDate) ? (
                    <>
                      <div>
                        <p>
                          Your {planName()} partnership was canceled on{" "}
                          {useDateFormat(expirationDate, "T")} due to
                          non-payment.
                        </p>
                      </div>
                      <div className={classes.planUpgrade}>
                        <Link to={APPLICATION_ROUTES.PARTNERS_PACKAGES}>
                          Renew Now
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      {partnership.details["status"] ==
                        PARTNERSHIP_STATUS.EXPIRED && (
                        <div>
                          <p>
                            You canceled your {planName()} partnership on{" "}
                            {useDateFormat(partnershipLastUpdated, "T")}.
                          </p>
                          <br />
                          <p>
                            You will not be able access benefits after{" "}
                            {useDateFormat(expirationDate, "T")}
                          </p>
                          <div className={classes.planUpgrade}>
                            <Link to={APPLICATION_ROUTES.PARTNERS_PACKAGES}>
                              Renew Now
                            </Link>
                          </div>
                        </div>
                      )}
                      <>
                        {partnership?.details?.trial_ends_at &&
                        partnership.details["plan"] == BRONZE_PLAN_ID ? (
                          ""
                        ) : (
                          <button
                            className={classes.renewOnButton}
                            disabled={partnership.getIsLoading(
                              ACTIONS.PARTNERSHIP.AUTO_RENEWAL_ON
                            )}
                            onClick={turnOnAutoRenewal}
                          >
                            {partnership.getIsLoading(
                              ACTIONS.PARTNERSHIP.AUTO_RENEWAL_ON
                            )
                              ? "Loading"
                              : "Turn ON Auto Renewal"}
                          </button>
                        )}
                        {/* <hr className={cs(classes.boundary)} /> */}
                        <p className={cs(classes.planCancel)}>
                          Your partnership will expire on{" "}
                          {useDateFormat(expirationDate, "T")}.
                        </p>
                      </>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <span className={cs(classes.planName)}>
                You are a: <br /> <strong>Free User</strong>
              </span>
              <div className={cs("mt-03", classes.becomePartner)}>
                <p>
                  {" "}
                  <Link to={APPLICATION_ROUTES.PARTNERS_PACKAGES}>
                    {partnership.getIsLoading(
                      ACTIONS.PARTNERSHIP.GET_PARTNERSHIP
                    )
                      ? "Loading"
                      : "Become Partner"}
                  </Link>
                </p>
              </div>
            </>
          )}
          <hr className={cs(classes.boundary)} />
          {!donation[`isLoading${getAllSusbsciptionAction}`] &&
          !donation[`isLoading${cancelDonationAction}`] ? (
            <>
              {donation.hasData && donationActive ? (
                <>
                  {donationActive ? (
                    <>
                      {donation.data.map((details) => {
                        return (
                          <>
                            {" "}
                            {details.status == DONATION_STATUS.ACTIVE ? (
                              <li className={cs("")}>
                                <span className={classes.planName}>
                                  {details.plan_id ==
                                    "c_donation monthly pledge" && (
                                    <strong>{"Monthly Pledge"}</strong>
                                  )}
                                  {details.plan_id !=
                                    "c_donation monthly pledge" && (
                                    <strong>
                                      {details.recurring_interval === 12
                                        ? "Yearly Donation"
                                        : "Monthly Donation"}
                                    </strong>
                                  )}
                                </span>
                                <span>
                                  ${details.plan_price.toFixed(2)}/
                                  {details.recurring_interval === 12
                                    ? "year"
                                    : "month"}
                                  {details.status == DONATION_STATUS.ACTIVE &&
                                    details.plan_id !=
                                      "c_donation monthly pledge" && (
                                      <small
                                        className={cs(
                                          classes.partnershipStatusText
                                        )}
                                      >
                                        Ongoing
                                      </small>
                                    )}
                                  {/* {details.plan_id ==
                                    "c_donation monthly pledge" && (
                                    <small
                                      className={cs(
                                        classes.partnershipStatusText
                                      )}
                                    >
                                      {useDateFormat(
                                        details.next_payment_date,
                                        "T"
                                      )}
                                    </small>
                                  )} */}
                                </span>
                                <span
                                  className={classes.planCancel}
                                  onClick={() => {
                                    cancelDonation(
                                      details.plan_price,
                                      details.recurring_interval,
                                      details.id
                                    );
                                  }}
                                >
                                  Cancel Your Donation Plan{" "}
                                </span>
                                <hr
                                  className={cs(classes.boundary, classes.red)}
                                />
                              </li>
                            ) : (
                              ""
                            )}{" "}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className={cs("mt-03", classes.startDonationButton)}>
                        <p>
                          <Link to={APPLICATION_ROUTES.PARTNERS_DONATION}>
                            {donation.getIsLoading(
                              ACTIONS.DONATION.GET_DONATION
                            )
                              ? "Loading"
                              : "Start a donation plan"}
                          </Link>
                        </p>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className={cs("mt-03", classes.startDonationButton)}>
                    <p>
                      <Link to={APPLICATION_ROUTES.PARTNERS_DONATION}>
                        {donation.getIsLoading(ACTIONS.DONATION.GET_DONATION)
                          ? "Loading"
                          : "Start a donation plan"}
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <Loader />
      )}
      <Modal
        disableOverlay={true}
        opened={isOpened}
        onClose={onClose}
        style={{ "z-index": "201" }}
      >
        <div className="wrapper-s pt-12 pb-12">
          <div className="br-06 pt-10 pb-10 pr-06 pl-06 bgc-white">
            {cancelDonationPlan && (
              <div className={cs("ta-center", classes.confimrationMessage)}>
                <Trans
                  components={{
                    a: <b />,
                    plan:
                      donationPlanInterval === 12
                        ? "yearly donation"
                        : "monthly donation",
                    amount: Number(donationPrice).toFixed(2),
                    planType: donationPlanInterval === 12 ? "year" : "month",
                  }}
                  i18nKey="donation_cancel.confirm_cancelation"
                  t={t}
                />
                <p className={cs("pt-03")}>
                  You will lose access to all the benefits of being a partner.
                </p>
              </div>
            )}

            <h3
              className={cs(
                "heading-l-a ta-center c-blue-dark",
                cancelDonationPlan && classes.fontChange
              )}
            >
              {t("partnership.are_you_sure")}
            </h3>
            <div className={cs("ta-center mt-07", classes.confimrationMessage)}>
              {!cancelDonationPlan && (
                <>
                  {partnership?.details?.trial_ends_at &&
                  partnership.details["plan"] == BRONZE_PLAN_ID ? (
                    <Trans
                      components={{
                        a: <b />,
                        plan: planName(),
                        date: useDateFormat(partnershipExpiration, "T"),
                      }}
                      i18nKey="partnership.free_trail_cancel"
                      t={t}
                    />
                  ) : (
                    <Trans
                      components={{
                        a: <b />,
                        plan: planName(),
                        date: useDateFormat(partnershipExpiration, "T"),
                      }}
                      i18nKey="partnership.confirm_cancelation"
                      t={t}
                    />
                  )}
                </>
              )}
            </div>

            {isAutoSignin != 1 ? (
              <div className={classes.passwordVerification}>
                <PasswordInput
                  error={
                    authorization[
                      `error${ACTIONS.AUTHORIZATION.AUTHENTICATE_PASSWORD}`
                    ]
                      ? t("layout.password_confirmation_failed")
                      : ""
                  }
                  id="sign-up-form-password"
                  label="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  stretched
                />
                <small>
                  <strong>
                    {cancelDonationPlan ? (
                      <>{t("donation_cancel.password_verification_message")} </>
                    ) : (
                      <> {t("partnership.password_verification_message")}</>
                    )}
                  </strong>
                </small>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex jc-space-around mt-07">
              {authorization[
                `isLoading${ACTIONS.AUTHORIZATION.AUTHENTICATE_PASSWORD}`
              ] ? (
                <Loader />
              ) : (
                <>
                  <Button
                    color={COLORS.GHOST}
                    onClick={() => {
                      cancelPlan();
                    }}
                    disabled={!enableCancelation}
                  >
                    {cancelDonationPlan ? (
                      <> {t("donation_cancel.yes_i_am_sure")} </>
                    ) : (
                      <> {t("partnership.yes_i_am_ok")}</>
                    )}
                  </Button>
                  <Button onClick={onClose}>{t("partnership.no")}</Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default Partnership;
