import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import cs from "classnames";

import Loader from "../../../../atoms/Loader";
import { COOKIES, PROJECTS } from "../../../../../constants";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import Gateway from "./components/Gateway";
import classes from "./Payment.module.scss";
import Cookies from "../../../../../utilities/cookies";

const Payment = observer(({ errorMessage }) => {
  const { t } = useTranslation();

  const { cart, checkout } = useContext(StoreContext);
  const hasError = checkout[`error${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`];
  // const paymentFailedError = checkout[`error${ACTIONS.CHECKOUT.SALE}`];
  const isLoadingGet =
    checkout[`isLoading${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`];
  const isAutoSignin = Cookies.get({ name: COOKIES.AUTO_SIGNIN });

  if (isAutoSignin == 1) {
    checkout.setValue("reAuthenticated", true);
  }

  useEffect(() => {
    if (cart.isNotEmpty) {
      // TODO: remove projectID from request
      checkout.createTransaction({
        projectID: PROJECTS.PARTNERS.ID,
      });
    }

    return () => {
      checkout.clear();
    };
  }, [cart.items.length]);

  if (hasError) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper, classes.message, "mb-05")}>
          {t("generals.warnings.1")}
        </div>
      </div>
    );
  }

  if (isLoadingGet) {
    return (
      <div className={classes.container}>
        <div className={cs(classes.wrapper, classes.loader)}>
          <Loader />
        </div>
      </div>
    );
  }

  if (checkout.hasNoTransition || checkout.hasNoToken) {
    return (
      <div className={classes.container}>
        <div className={classes.wrapper} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Gateway />
      </div>
      {errorMessage && (
        <div className={cs(classes.error, "ta-center")}>{errorMessage}</div>
      )}
      {/* {paymentFailedError && (
        <div className={cs(classes.error, "ta-center")}>
          There was an error in processing your request. Please check your details and try again.
        </div>
      )} */}
    </div>
  );
});

export default Payment;
