import React, { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../../../contexts";
import classes from "./Playlist.module.scss";
import Icon, { NAMES, SIZES } from "../../atoms/Icon";
// import Switch from "../../atoms/Switch";
import cs from "classnames";
import Player from "../Player";
import { Link, useHistory } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constants";
import { getURL } from "../../pages/Library/components/Project/components/Preview/utilities";
import { ACTIONS as PLAYLIST_ACTION } from "../../../store/playlist/utilities";
import placeholder from "../../../assets/images/video-thumbnail-placeholder.png";
import Loader from "../../atoms/Loader";
import { useTranslation } from "react-i18next";
import RadioDots from "../../atoms/RadioDots";
// import Button from "../../atoms/Button";
// import Select from "../../atoms/Select";
import SelectR from "../../atoms/SelectR";
import { ACTIONS } from "../../../store";
// import Persons from "../../molecules/Persons";
import Add from "../../molecules/Persons/components/Add";
import gift from "../../../assets/svg/birthdayGift.svg";
import ballon from "../../../assets/svg/ballons.svg";
import pencil from "../../../assets/images/pencil.png";
import pencilRight from "../../../assets/images/pencilRIght.png";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useState } from "react";
import { useModal } from "../../../hooks";
import { AuthorizationKids } from "../RestrictionModal/RestrictionModal";
import SubmitAnswerButton from "../../atoms/SubmitAnswerButton";
import ShareButton from "../../atoms/ShareButton";
import ColoringPageButton from "../../atoms/ColoringPageButton";
// import { scrollToTop } from "../../../utilities/helpers";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const PlaylistModal = observer(({ video, hideLibrary, page }) => {
  const { playlist, library, authorization, partnership } =
    useContext(StoreContext);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [pHeight, setPHeight] = useState(0);
  const [dynamicHeight, setDynamicHeight] = useState(430);
  const history = useHistory();

  const signUpModal = useModal();

  const playerWrapRef = useRef(null);

  console.log(video.file, "videovideo");
  const toggleDownload = () => {
    const url = `${video.file}?download=video.mp4`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      setDynamicHeight(windowSize.innerHeight - pHeight - 100);
    }

    setPHeight(playerWrapRef.current.clientHeight);

    setDynamicHeight(windowSize.innerHeight - pHeight - 100);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [pHeight]);

  const { t } = useTranslation();

  // const [descriptionShown, setDescriptionShown] = useState(false);

  const onLoad = () => {
    playlist.get({ onSuccess: null });
  };

  const getOptions = (options) => {
    const result = [];
    options.map((option) => {
      result.push({
        id: option.id,
        label: option.option,
        value: option.id,
      });
    });

    return result;
  };

  const sharePage = async () => {
    try {
      await navigator.share({
        url: window.location.origin + "/" + video.url,
        title: video.name,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const navigateToBirthday = () => {
    history.push(APPLICATION_ROUTES.MY_MAOR_MAIN);
    try {
      setTimeout(() => {
        document.getElementById("birthday-package").scrollIntoView();
      }, 500);
    } catch (e) {
      console.warn(e);
    }
  };

  // const submitAnswerHandle = () => {
  //   if (!authorization.isAuthenticated) {
  //     signUpModal.onOpen();
  //   } else {
  //     video.showQuestionScreen
  //   }

  // }

  const navigateToPartner = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_MAIN);
  };

  const dynamicHeightCss = isMobile
    ? {
        maxHeight: dynamicHeight + "px",
      }
    : {};

  console.log(dynamicHeightCss);

  let pType = "MyMaor";

  try {
    pType == "" ? "MyMaor" : "DailyRebbeVideo";
  } catch (e) {
    console.warn(e);
  }

  return (
    <div
      data-name="video-overlay"
      className={cs(
        classes.overlay,
        hideLibrary ? classes.myMaorVideo : "",
        isMobile ? classes.mobileoverlay : ""
      )}
      role="button"
      onClick={video.onClickOverlay}
    >
      <div
        className={cs(
          classes.player,
          isMobile ? classes.mobilePlayer : "",
          video.showQuestion || video.addChild ? classes.questionbg : ""
        )}
        onClick={video.stopTimer}
      >
        <div
          onClick={() => {
            video.onClose();
          }}
          className={cs(
            classes.closeContainer,
            hideLibrary ? "d-none" : ""
            // isMobile ? classes.closeMobileContainer : ""
          )}
        >
          <Icon
            size="xs"
            name="x"
            color={isMobile ? "orange" : ""}
            cursorPointer={true}
          />
        </div>
        <div
          className={cs(
            classes.playerboxWrapper,
            !hideLibrary && isMobile ? classes.playlistPlayer : "",
            video.showQuestion || video.addChild
              ? classes.playerQuesWrapper
              : ""
          )}
        >
          <div ref={playerWrapRef} className={cs(classes.playerWrapper)}>
            {!isMobile ? (
              <BrowserView>
                {hideLibrary ? (
                  ""
                ) : (
                  <div className={classes.VideoHeading}>
                    <div className={cs(classes.videoHeadingInfo)}>
                      <span>#{video.number} |</span>
                      <h3
                        className={cs(
                          classes.test,
                          "tt-uppercase",
                          "heading-s-a"
                        )}
                      >
                        {video.name}
                      </h3>
                    </div>
                    <span className={cs(classes.description)}>
                      {video.description}
                    </span>
                  </div>
                )}

                <div className={cs(classes.videoInformation, "d-none")}>
                  <div className={classes.videoInfoWrapper}>
                    {video.categories &&
                      video.categories.map((category, index) => {
                        return (
                          <span
                            key={`${category.id}-${index}`}
                            className={classes.videoCategoryName}
                          >
                            {category.name_english}
                          </span>
                        );
                      })}
                    <span className={classes.videoDateNumber}>
                      #{video.number}
                    </span>
                  </div>
                  {/* <div className={"d-flex ai-center gap-1"}>
                    {!video.showQuestion && video.totalQuestions > 0 && (
                      <>
                        {!isMobile ? (
                          <BrowserView>
                            <p
                              onClick={(e) => {
                                if (!authorization.isAuthenticated) {
                                  signUpModal.onOpen();
                                } else {
                                  video.showQuestionScreen(e)
                                }
                              }}
                              className={cs(
                                classes.submitAnswer,
                                "fw-700 c-orange"
                              )}
                            >
                              Submit Answer
                            </p>
                          </BrowserView>
                        ) : null}{" "}
                      </>
                    )}

                    {video.file != null && (
                      <div className={classes.videoActionWrapper}>
                        <a
                          download
                          className={classes.pdfWrap}
                          rel="noreferrer"
                          target="_blank"
                          href={video.file}
                        >
                          <Icon size="l" name="pdf" />
                        </a>
                      </div>
                    )}
                  </div> */}
                </div>
              </BrowserView>
            ) : null}

            {video.addChild ? (
              <Add
                back={video.backFromChildForm}
                onSuccess={video.removeAddChildForm}
              />
            ) : (
              <>
                {video.showQuestion &&
                video.totalQuestions > 0 &&
                authorization.isAuthenticated ? (
                  <div
                    className={cs(
                      classes.questionContainer,
                      isMobile ? classes.mobileQuestions : "",
                      "ta-center"
                    )}
                  >
                    <div
                      className={cs(
                        video.showQuestion || video.addChild
                          ? classes.whiteBg
                          : "",
                        video.answerSubmitted ? classes.answersWrap : ""
                      )}
                    >
                      {video.showTimer == true && video.questionTimer > 0 && (
                        <div className={classes.timerContainer}>
                          <div className={classes.countdownTimer}>
                            <p>
                              <span>{video.questionTimer}</span>
                            </p>
                          </div>
                        </div>
                      )}

                      {video.answerSubmitted
                        ? video.showAnswerSubmitTimer != true && (
                            <div
                              onClick={() => {
                                video.onClose();
                              }}
                              className={cs(classes.closeContainer)}
                            >
                              <Icon
                                size="xs"
                                name="x"
                                color={isMobile ? "orange" : ""}
                                cursorPointer={true}
                              />
                            </div>
                          )
                        : video.showTimer != true && (
                            <div
                              onClick={() => {
                                video.onClose();
                              }}
                              className={cs(classes.closeContainer)}
                            >
                              <Icon
                                size="xs"
                                name="x"
                                color={isMobile ? "orange" : ""}
                                cursorPointer={true}
                              />
                            </div>
                          )}

                      {video.answerSubmitted ? (
                        <>
                          {authorization.isUserMember ? (
                            <div className={classes.answerSubmitted}>
                              <div className={classes.answerHeader}>
                                <img
                                  src={pencil}
                                  className={cs(classes.leftImg)}
                                />
                                <div>
                                  <h3 className="fw-700 mb-03">
                                    Answer Submitted!
                                  </h3>
                                  {library?.videoQuestion?.isCorrect ? (
                                    <p className="fw-700">
                                      You have entered the raffle
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <img
                                  src={pencilRight}
                                  className={cs(classes.rightImg)}
                                />
                              </div>
                              <p className="mt-05">
                                Watch next video to see the correct answer and
                                weekly winner. It might be you...:)
                              </p>
                              <p>
                                <p
                                  className={cs(classes.submitAnotherChild)}
                                  onClick={video.showQuestionScreen}
                                >
                                  Submit answer for another child
                                </p>
                                {/* <Link onClick={video.showQuestionScreen}>
                                  Submit answer for another child
                                </Link> */}
                              </p>
                              {video.thankyouScreenTimer > 0 &&
                                video.showAnswerSubmitTimer && (
                                  <div className={classes.timerContainer}>
                                    <div className={classes.countdownTimer}>
                                      <p>
                                        <span>{video.thankyouScreenTimer}</span>
                                      </p>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ) : (
                            <div className={classes.answerSubmittedGuest}>
                              <div className={classes.answerHeaderGuest}>
                                <div>
                                  <h3
                                    className={cs(
                                      classes.screenHeading,
                                      "fw-700 mb-03"
                                    )}
                                  >
                                    Would You Like to Watch More?
                                  </h3>
                                </div>
                              </div>

                              <div className={classes.questionOptionsGuest}>
                                <button
                                  type="submit"
                                  onClick={navigateToPartner}
                                  className={cs(
                                    classes.submitBtn,
                                    "c-orange fw-700"
                                  )}
                                >
                                  Become a Partner Today!
                                </button>
                                <p className="mt-02 fs-italic">
                                  14 day Free Trial
                                </p>
                              </div>

                              <p className={cs(classes.pHeading, "mt-05")}>
                                to enjoy our full library.
                              </p>
                              <p className={cs(classes.pHeading, "mt-05")}>
                                And.. you can even get birthdays included!
                              </p>
                              {/* {video.thankyouScreenTimer > 0 && (
                                <div className={classes.timerContainer}>
                                  <div className={classes.countdownTimer}>
                                    <p>
                                      <span>{video.thankyouScreenTimer}</span>
                                    </p>
                                  </div>
                                </div>
                              )} */}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={classes.questionContainerDesktop}>
                          {/* <h3>Submit Your Answer:</h3> */}
                          <div className={classes.dropdownContainer}>
                            <p>
                              <SelectR
                                selectStyle="answers"
                                id="children"
                                label={video.children.label}
                                options={video.children.options}
                                value={video.children.value}
                                onChange={video.children.onChange}
                              />
                            </p>
                            <p
                              className={cs("mt-03", classes.showAddChildForm)}
                            ></p>
                          </div>
                          {video.questions.map(
                            (question, i) =>
                              i + 1 == parseInt(video.currentQuestion) && (
                                <div key={i} className={classes.questionInner}>
                                  <h3 className={cs("heading-s-a mb-06")}>
                                    {question.question}
                                  </h3>
                                  <div className={classes.options}>
                                    <RadioDots
                                      additionalClass="orangeRadio"
                                      items={getOptions(question.options)}
                                      value={parseInt(video.currentAnswer)}
                                      onChange={(e) => {
                                        video.setAnswer(e, question.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                          )}
                          <div className={classes.questionOptions}>
                            <button
                              type="submit"
                              onClick={video.submitQuestion}
                              className={cs(
                                classes.submitBtn,
                                "c-orange fw-700"
                              )}
                            >
                              Submit your Answer
                            </button>
                            {library[
                              `error${ACTIONS.LIBRARY.SAVE_VIDEO_QUESTION_OPTION}`
                            ] && (
                              <p className={cs(classes.error, "fw-700 mt-02")}>
                                You have already answered this question.
                              </p>
                            )}
                            {video.childrenError && (
                              <p className={cs(classes.error, "fw-700 mt-02")}>
                                Please select your name from the list above
                              </p>
                            )}
                            {video.optionsError && (
                              <p className={cs(classes.error, "fw-700 mt-02")}>
                                Please select an answer from the list above
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={cs(
                      classes.vimeoPlayer,
                      (page == "daily-rebbe-video" || page == "illuminate") &&
                        classes.hideBackgroundColor,
                      (page !== "daily-rebbe-video" || page !== "illuminate") &&
                        hideLibrary == 1 &&
                        !isMobile &&
                        classes.homePLayer,
                      hideLibrary != 1 && isMobile && classes.mobilePlaylist,
                      hideLibrary == 1 && classes.modalMyPlaylist
                    )}
                    data-item={video.totalQuestions + "-" + video.showQuestion}
                  >
                    <Player
                      additionalClass={
                        isMobile ? "mobileHeight" : "desktopHeight"
                      }
                      paused={video.paused}
                      start={video.start}
                      autoplay
                      video={video.url}
                      onTimeUpdate={video.onUpdate}
                      onEnd={video.onEnd}
                      page={page}
                      muted={isMobile ? true : false}
                    />
                  </div>
                )}
              </>
            )}

            {isMobile ? (
              <MobileView>
                {!video.addChild &&
                  !video.showQuestion &&
                  hideLibrary != "1" && (
                    <div className={classes.videoTagsMobile}>
                      {/* <span className="mr-02">
                      {pType} #{video.number}
                    </span>
                    <a
                      href="javascript:void();"
                      style={{ color: "#99b8ef" }}
                      key={`video-internal-${video.internal_id}`}
                    >
                      {video.internal_id}
                    </a> */}
                      {video.tags &&
                        video.tags.map((tag, index) => (
                          <Link
                            target="_blank"
                            key={tag.id + index}
                            to={`${APPLICATION_ROUTES.LIBRARY}?keyword=${tag.name_english}`}
                          >
                            #{tag.name_english}
                          </Link>
                        ))}
                    </div>
                  )}
                {hideLibrary != 1 && (
                  <div className={classes.VideoHeading}>
                    <div className={classes.VideoHeadingWrap}>
                      <h3 className={cs(classes.titleText, "heading-s-a")}>
                        #{video.number} |{" "}
                        <span className={cs("c-orange", classes.bold)}>
                          {" "}
                          {video.name}{" "}
                        </span>
                      </h3>
                      {/* {video.description && (
                        <Icon
                          onClick={() => {
                            setDescriptionShown(!descriptionShown);
                          }}
                          name={
                            descriptionShown ? "arrow-dropup" : "arrow-dropdown"
                          }
                        />
                      )} */}
                    </div>
                    <div
                      className={cs(
                        // descriptionShown ? "d-block" : "d-none",
                        "d-block",
                        "mb-03"
                      )}
                    >
                      {video.description}
                    </div>
                    <div className={cs(classes.videoButtonMobile)}>
                      <ShareButton size="xs" sharePage={sharePage} />
                      {video.file != null && (
                        <>
                          <ColoringPageButton
                            size="xs"
                            pdfHandler={toggleDownload}
                          />
                          <a
                            className={"d-none"}
                            rel="noreferrer"
                            target="_blank"
                            href={video.file}
                            download={"video.mp4"}
                          />
                        </>
                      )}
                      {!video.showQuestion && video.totalQuestions > 0 && (
                        <SubmitAnswerButton
                          size="xs"
                          submitAnswer={(e) => {
                            if (!authorization.isAuthenticated) {
                              signUpModal.onOpen();
                            } else {
                              video.showQuestionScreen(e);
                            }
                          }}
                          color="colorLightYellow"
                        />
                      )}
                    </div>
                  </div>
                )}
              </MobileView>
            ) : (
              <BrowserView>
                {page !== "daily-rebbe-video" && page !== "illuminate" && (
                  <>
                    {!video.addChild && !video.showQuestion && (
                      <div
                        className={cs(
                          classes.videoFooterInformation,
                          classes.videoInformationBottom,
                          hideLibrary != 1 && classes.modalMyPlaylistFooter
                        )}
                      >
                        <>
                          <div className={classes.videoTags}>
                            <a
                              key={`video-id-${video.internal_id}`}
                              style={{ color: "#99b8ef" }}
                              href="javascript:void();"
                            >
                              {video.internal_id}
                            </a>
                            {video.tags &&
                              video.tags.map((tag, index) => (
                                <Link
                                  target="_blank"
                                  key={tag.id + index}
                                  to={`${APPLICATION_ROUTES.LIBRARY}?keyword=${tag.name_english}`}
                                >
                                  #{tag.name_english}
                                </Link>
                              ))}
                          </div>
                          <div className={cs(classes.videoButton)}>
                            <ShareButton size="xs" sharePage={sharePage} />
                            {video.file != null && (
                              <>
                                <ColoringPageButton
                                  size="xs"
                                  pdfHandler={toggleDownload}
                                />
                                <a
                                  className={"d-none"}
                                  rel="noreferrer"
                                  target="_blank"
                                  href={video.file}
                                  download={"video.mp4"}
                                />
                              </>
                            )}
                            {!video.showQuestion &&
                              video.totalQuestions > 0 && (
                                <SubmitAnswerButton
                                  size="xs"
                                  submitAnswer={(e) => {
                                    if (!authorization.isAuthenticated) {
                                      signUpModal.onOpen();
                                    } else {
                                      video.showQuestionScreen(e);
                                    }
                                  }}
                                />
                              )}
                          </div>
                          {/* <div className={classes.skipDedication}>
                        {t("components.organisms.playlist_modal.skip_intro")}
                        <Switch
                          checked={video.skipDedication}
                          handleToggle={() => {
                            video.setSkipDedication(!video.skipDedication);
                          }}
                        />
                      </div> */}
                        </>
                      </div>
                    )}
                  </>
                )}
              </BrowserView>
            )}

            {
              // isMobile ? (
              //   <MobileView>
              //     {hideLibrary != 1 && (
              //       <div className={classes.skipDedicationWrap}>
              //         <div className={cs(classes.skipDedicationInner, "d-flex")}>
              //           <button
              //             className={cs(classes.clearBtn)}
              //             disabled={playlist.getIsLoading(
              //               PLAYLIST_ACTION.CLEAR_PLAYLIST
              //             )}
              //             onClick={() => {
              //               playlist.clear({
              //                 onSuccess: () => {
              //                   playlist.get({
              //                     onSuccess: null,
              //                     resetPage: true,
              //                   });
              //                 },
              //               });
              //             }}
              //           >
              //             {playlist.getIsLoading(PLAYLIST_ACTION.CLEAR_PLAYLIST)
              //               ? t("components.organisms.playlist_modal.clearing")
              //               : t(
              //                   "components.organisms.playlist_modal.clear_playlist"
              //                 )}
              //           </button>
              //           {/* < href="" className={cs(classes.clearBtn)}>Clear Playlist</a> */}
              //           <div className={classes.skipDedication}>
              //             {t("components.organisms.playlist_modal.skip_intro")}
              //             <Switch
              //               checked={video.skipDedication}
              //               handleToggle={() => {
              //                 video.setSkipDedication(!video.skipDedication);
              //               }}
              //             />
              //           </div>
              //         </div>
              //         {!video.showQuestion && video.totalQuestions > 0 && (
              //           <p
              //             onClick={(e) => {
              //               if (!authorization.isAuthenticated) {
              //                 signUpModal.onOpen();
              //               } else {
              //                 video.showQuestionScreen(e);
              //               }
              //             }}
              //             className={cs(classes.submitAnswer, "fw-700 c-orange")}
              //           >
              //             Submit Answer
              //           </p>
              //         )}
              //       </div>
              //     )}
              //   </MobileView>
              // ) : null
            }

            {/* {hideLibrary == "1" && !video.showQuestion && (
              <p onClick={video.showQuestionScreen} className={cs(classes.submitAnswer, "fw-700 c-orange")}>
              Submit Answer
              </p>
            )} */}
          </div>

          {hideLibrary != "1" && (
            <div className={cs(classes.playlistBoxContainer)}>
              <div
                className={cs(classes.playlistBox, hideLibrary ? "d-none" : "")}
              >
                <div className={classes.playlistCount}>
                  <div className={classes.playlistCountInner}>
                    <span>
                      <Icon
                        name="playlist"
                        size={isMobile ? SIZES.XS : SIZES.S}
                      />{" "}
                      {t("components.organisms.playlist_modal.playlist")} (
                      {playlist.totalItems})
                    </span>
                  </div>
                </div>

                <div
                  className={cs(
                    classes.playlistBoxInner,
                    playlist.totalItems == 0 ? classes.emptylist : ""
                    // isMobile ? classes.mobilePlaylistBox : ""
                  )}
                >
                  <div
                    // style={dynamicHeightCss}
                    className={cs(
                      classes.playlistItems,
                      isMobile ? classes.mobilePlaylist : ""
                    )}
                  >
                    {playlist.items.map((playlistItem, index) => {
                      let projectTypeName =
                        playlistItem.video.project_id == 2 ? "MyMaor" : "DRV";
                      return (
                        <>
                          <div
                            key={playlist.id}
                            className={cs(classes.playlistItem, {
                              [classes.videoPlaying]:
                                playlist.getCurrentPlayingVideoId() ==
                                playlistItem.video.id,
                            })}
                          >
                            <div
                              onClick={() => {
                                playlist.setCurrentPlayingVideoId(
                                  playlistItem.video.id
                                );

                                video.updateVideo({
                                  end: playlistItem.video.end,
                                  start: playlistItem.video.start,
                                  url: getURL(playlistItem.video.url, {
                                    start: playlistItem.video.start,
                                  }),
                                  name: playlistItem.video.name,
                                  description: playlistItem.video.description,
                                  number: playlistItem.video.number,
                                  date: playlistItem.video.created_at,
                                  categories:
                                    playlistItem.video.video_directory
                                      .video_categories,
                                  questions: playlistItem.video.video_questions,
                                  tags: playlistItem.video.video_directory
                                    .video_tags,
                                  holiday:
                                    playlistItem.video.video_directory.holiday,
                                  file: playlistItem.video.video_directory.file,
                                  showQuestion: false,
                                  answerSubmitted: false,
                                  internal_id:
                                    playlistItem.video.video_directory
                                      .internal_id,
                                  info: {
                                    projectType:
                                      playlistItem.video.project_id == 2
                                        ? ""
                                        : "daily-rebbe",
                                  },
                                });
                              }}
                              className={cs(classes.playlistItemInfoWrap)}
                            >
                              {/* {!isMobile ? (
                            <BrowserView> */}
                              {playlist.getCurrentPlayingVideoId() ==
                              playlistItem.video.id ? (
                                <Icon
                                  name={NAMES.PlayBlackIcon}
                                  size={SIZES.XXS}
                                />
                              ) : (
                                <div className={classes.videoNumbers}>
                                  {index + 1}
                                </div>
                              )}
                              {/* </BrowserView>
                          ) : null} */}

                              <div className={cs(classes.playlistItemInfo)}>
                                <img
                                  width="100"
                                  src={
                                    playlistItem.video.thumbnail
                                      ? playlistItem.video.thumbnail
                                      : placeholder
                                  }
                                />
                                {playlistItem.video.duration_formatted && (
                                  <span className={cs(classes.videoTime)}>
                                    {playlistItem.video.duration_formatted}
                                  </span>
                                )}
                              </div>
                              <div className={cs(classes.playlistItemName)}>
                                <div className={classes.videoName}>
                                  {playlistItem.video.name}
                                </div>
                                <div className={classes.playlistCatInfo}>
                                  <small
                                    className={cs(classes.videoCategoriesName)}
                                  >
                                    {projectTypeName} #
                                    {playlistItem.video.number}
                                  </small>
                                  {playlistItem.video.video_directory
                                    .video_categories.length > 0 && (
                                    <span
                                      className={cs(
                                        classes.videoCategoriesName,
                                        classes.dot
                                      )}
                                    ></span>
                                  )}
                                  {playlistItem.video.video_directory
                                    .video_categories &&
                                    playlistItem.video.video_directory.video_categories.map(
                                      (category, index) => {
                                        return (
                                          <small
                                            className={cs(
                                              classes.videoCategoriesName
                                            )}
                                            key={`${category.id}-${index}`}
                                          >
                                            {category.name_english}
                                          </small>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className={cs(classes.playlistItemRemove)}>
                              {playlist.loadingIds.includes(
                                playlistItem.video.id
                              ) ? (
                                <Loader />
                              ) : (
                                <div
                                  onClick={() => {
                                    let videoId = playlistItem.video.id;
                                    playlist.setLoadingId(videoId);
                                    playlist.delete({
                                      id: videoId,
                                      onSuccess: () => {
                                        playlist.get({
                                          onSuccess: () => {
                                            playlist.removeLoadingId(videoId);
                                          },
                                          refreshCurrentPage: true,
                                        });
                                      },
                                    });
                                  }}
                                >
                                  <Icon
                                    cursorPointer={true}
                                    name="cross-big"
                                    size="xxs"
                                    color="orange"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {playlist.totalItems == 0 ? (
                      <div className={classes.emptyplaylist}>
                        {t("components.organisms.playlist_modal.empty")}
                      </div>
                    ) : null}
                    {playlist.hasNextPage && (
                      <div className={classes.footer}>
                        {playlist.getIsLoading(PLAYLIST_ACTION.GET) ? (
                          <Loader />
                        ) : (
                          <button className={classes.button} onClick={onLoad}>
                            {t("components.organisms.playlist_modal.show_more")}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={cs(
                  classes.playlistBoxFooter,
                  playlist.totalItems == 0 ? classes.hideClearPlaylist : ""
                )}
              >
                {playlist.totalItems ? (
                  <div className={classes.playlistFooter}>
                    <button
                      disabled={playlist.getIsLoading(
                        PLAYLIST_ACTION.CLEAR_PLAYLIST
                      )}
                      onClick={() => {
                        playlist.clear({
                          onSuccess: () => {
                            playlist.get({
                              onSuccess: null,
                              resetPage: true,
                            });
                          },
                        });
                      }}
                    >
                      <p>
                        {playlist.getIsLoading(PLAYLIST_ACTION.CLEAR_PLAYLIST)
                          ? t("components.organisms.playlist_modal.clearing")
                          : t(
                              "components.organisms.playlist_modal.clear_playlist"
                            )}
                      </p>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {hideLibrary != 1 && (
            <>
              {" "}
              {isMobile ? (
                <MobileView className={cs(classes.mobileView, "d-none")}>
                  <div className={cs(classes.playlistBoxFooterInner)}>
                    <Link
                      className={cs(
                        classes.birthdayLink,
                        "c-orange fw-700 d-flex"
                      )}
                      // to={APPLICATION_ROUTES.MY_MAOR_MAIN}
                      onClick={navigateToBirthday}
                    >
                      <img src={gift} className={cs(classes.leftImg)} />
                      <div className={cs(classes.playlistFooterContent)}>
                        <p className={cs(classes.pinkColor, "fw-700 mb-01")}>
                          Make Your child&apos;s birthday special!
                        </p>
                        - Find Out More -
                      </div>
                      <img src={ballon} className={cs(classes.rightImg)} />
                    </Link>
                  </div>
                </MobileView>
              ) : null}
            </>
          )}
        </div>
      </div>
      <AuthorizationKids
        popup={signUpModal}
        authorization={authorization}
        partnership={partnership}
      />
    </div>
  );
});

export default PlaylistModal;
