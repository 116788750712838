import { useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { APPLICATION_ROUTES } from "../../../constants";
import { StoreContext } from "../../../contexts";
import { NAMES } from "../../../utilities/validation";
import { useDoubleInput, useInput, usePhone, useQuery, useRadio } from "../../../hooks";

const useForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const shouldSkipVerification = location.search.includes("redirect=");

  const history = useHistory();

  const onNavigateToQuestionnaire = useCallback(({ emailAddress }) => {
    if (shouldSkipVerification === false) {
      history.push(APPLICATION_ROUTES.VERIFY_EMAIL + "?email=" + emailAddress);
    } else {
      const redirectValue = searchParams.get("redirect");
      history.push(redirectValue);
    }
  }, []);

  const { authorization } = useContext(StoreContext);
  const query = useQuery();
  const { t } = useTranslation();
  const CONFIRM_PASSWORD = t("forms.labels.confirm_password");
  const EMAIL = t("forms.labels.email");
  const FIRST_NAME = t("forms.labels.first_name");
  const LAST_NAME = t("forms.labels.last_name");
  const PASSWORD = t("forms.labels.password");
  const AT_LEAST_SIX_SYMBOLS = t("forms.messages.at_least_number_symbols", {
    number: 6,
  });
  // const ONLY_LATIN_LETTERS_ARE_ALLOWED = t(
  //   "forms.messages.only_latin_letters_are_allowed"
  // );
  const THE_FIELD_DOES_NOT_MATCH = t("forms.messages.the_field_does_not_match");
  const THIS_EMAIL_IS_NOT_VALID = t("forms.messages.this_email_is_not_valid");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");
  const REFERRAL_CODE = t("forms.labels.referral_code");
  const FIRST_NAME_LENGTH = t(
    "forms.messages.a_first_name_must_have_a_minimum_of_2_characters"
  );
  const LAST_NAME_LENGTH = t(
    "forms.messages.a_last_name_must_have_a_minimum_of_2_characters"
  );

  const ageStatus = useRadio({
    options: [
      {
        id: "sign-up-form-age-status-adult",
        label: t("forms.labels.adult"),
        value: "adult",
      },
      {
        id: "sign-up-form-age-status-child",
        label: t("forms.labels.child"),
        value: "child",
      },
    ],
    value: "adult",
  });

  const firstName = useInput({
    label: FIRST_NAME,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.LATIN,
      //   options: { message: ONLY_LATIN_LETTERS_ARE_ALLOWED },
      // },
      {
        name: NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("forms.labels.first_name"),
          }),
        },
      },
      {
        name: NAMES.MIN_2,
        options: { message: FIRST_NAME_LENGTH },
      },
    ],
  });
  const email = useInput({
    label: EMAIL,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      { name: NAMES.EMAIL, options: { message: THIS_EMAIL_IS_NOT_VALID } },
    ],
  });

  const lastName = useInput({
    label: LAST_NAME,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      // {
      //   name: NAMES.LATIN,
      //   options: { message: ONLY_LATIN_LETTERS_ARE_ALLOWED },
      // },
      {
        name: NAMES.MAX_64,
        options: {
          message: t("forms.messages.max_64_characters", {
            value: 64,
            field: t("forms.labels.last_name"),
          }),
        },
      },
      {
        name: NAMES.MIN_2,
        options: { message: LAST_NAME_LENGTH },
      },
    ],
  });

  const referralCode = useInput({
    label: REFERRAL_CODE,
    validators: [],
  });

  const password = useDoubleInput({
    first: "Original",
    second: "Confirm",
    labelOfFirst: PASSWORD,
    labelOfSecond: CONFIRM_PASSWORD,
    validatorsOfFirst: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      {
        name: NAMES.MIN_SIX_SYMBOLS,
        options: { message: AT_LEAST_SIX_SYMBOLS },
      },
    ],
    validatorsOfSecond: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      {
        name: NAMES.MIN_SIX_SYMBOLS,
        options: { message: AT_LEAST_SIX_SYMBOLS },
      },
      { name: NAMES.EQUAL, options: { message: THE_FIELD_DOES_NOT_MATCH } },
    ],
  });
  const phone = usePhone();

  const isNotValidFirstStep = !!(
    lastName.errors.length ||
    firstName.errors.length ||
    email.errors.length ||
    phone.errors.length 
  );
  const isNotValidSecondStep = !!(
    lastName.errors.length ||
    firstName.errors.length ||
    email.errors.length ||
    password.errorsOfOriginal.length ||
    password.errorsOfConfirm.length ||
    phone.errors.length 
  );
  const isNotValidForm = isNotValidFirstStep || isNotValidSecondStep;

  let referredFrom = "";
  if (navigator.cookieEnabled) {
    referredFrom = localStorage.getItem("referral-code");
  }

  const onSubmit = (event) => {
    event.preventDefault();
    
    const data = {
      ageStatus: firstName.value ? ageStatus.value : "",
      firstName: firstName.value,
      referralCode: referralCode.value,
      email: email.value,
      lastName: lastName.value,
      password: password.valueOfOriginal,
      phone: !phone.errors.length ? phone.value : "",
      referredFrom: referredFrom,
      onSuccess: () => {
        if (query.get('redirect')) {
          window.location = query.get('redirect');
        } else {
          onNavigateToQuestionnaire({ emailAddress: email.value });
        }
      },
    };

    authorization.signUp(data);
    if (navigator.cookieEnabled) {
      localStorage.removeItem("referral-code");
    }
  };

  return {
    ageStatus,
    email,
    firstName,
    isNotValidFirstStep,
    isNotValidSecondStep,
    isNotValidForm,
    lastName,
    password,
    referralCode,
    phone,
    onSubmit,
  };
};

export default useForm;
