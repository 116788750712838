import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NAMES } from "../../utilities/validation";
import { createState, validate } from "./utilities";

const usePhone = ({ value = "", validators = [] } = {}) => {
  const [state, setState] = useState(createState({ value, validators }));
  const { t } = useTranslation();
  const THE_PHONE_NUMBER_IS_NOT_FULLY_ENTERED = t(
    "forms.messages.the_phone_number_is_not_fully_entered"
  );
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  useEffect(() => {
    setState((state) => {
      const errors = validate({
        value,
        validators: [
          ...validators,
          {
            name: NAMES.REQUIRED,
            options: { message: THIS_FIELD_IS_REQUIRED },
          },
          {
            name: NAMES.COMPLETED_PHONE_NUMBER,
            options: {
              message: THE_PHONE_NUMBER_IS_NOT_FULLY_ENTERED,
            },
          },
        ],
      });

      return {
        ...state,
        errors,
        value,
      };
    });
  }, []); 

  const onChange = useCallback(
    (value, data) => {
      setState((state) => {
        const errors = validate({
          value,
          validators: [
            ...validators,
            {
              name: NAMES.REQUIRED,
              options: { message: THIS_FIELD_IS_REQUIRED },
            },
            {
              name: NAMES.COMPLETED_PHONE_NUMBER,
              options: {
                code: data.countryCode,
                format: data.format,
                message: THE_PHONE_NUMBER_IS_NOT_FULLY_ENTERED,
              },
            },
          ],
        });

        return {
          ...state,
          errors: errors,
          touched: true,
          value: value,
        };
      });
    },
    [setState]
  );

  return {
    error: state.touched && state.errors.length ? state.errors[0] : "",
    errors: state.errors,
    touched: state.touched,
    value: state.value,
    onChange: onChange,
  };
};

export default usePhone;
