/* eslint-disable prettier/prettier */

import { useRef, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  useCheckbox,
  useInput,
  useRadio,
  useSelectR,
  useToggle,
} from "../../../hooks";
import { ACTIONS } from "../../../store";
import { NAMES } from "../../../utilities/validation";
import { convertToHebrew } from "jewish-dates-core";
import {
  convertJewishEngToJewish,
  formatJewishDate,
  getJewishEnglishMonth,
} from "../../../utilities/helpers";
import Hebcal from "hebcal";
import { StoreContext } from "../../../contexts";
import jsonToFormData from "../../../utilities/helpers/jsonToFromData";
import { CHARACTER_SUBMISSION_KEY } from "./WritingCompetitionForm";

let jewishDays = [];
let enDays = [];
for (let i = 1; i <= 30; i++) {
  jewishDays.push({
    label: convertToHebrew(i, false, false),
    value: convertToHebrew(i, false, false),
    englishDay: i,
  });
}

for (let i = 1; i <= 31; i++) {
  enDays.push({
    label: i,
    value: i,
  });
}

const heMonths = {
  0: {
    jewish: "תשרי",
    jewishEng: "Tishrei",
  },
  1: {
    jewish: "חשון",
    jewishEng: "Cheshvan",
  },
  2: {
    jewish: "כסלו",
    jewishEng: "Kislev",
  },
  3: {
    jewish: "טבת",
    jewishEng: "Tevet",
  },
  4: {
    jewish: "שבט",
    jewishEng: "Shevat",
  },
  5: {
    jewish: "אדר",
    jewishEng: "Adar",
  },
  6: {
    jewish: "אדר א",
    jewishEng: "Adar 1",
  },
  7: {
    jewish: "אדר ב",
    jewishEng: "Adar 2",
  },
  8: {
    jewish: "ניסן",
    jewishEng: "Nissan",
  },
  9: {
    jewish: "אייר",
    jewishEng: "Iyyar",
  },
  10: {
    jewish: "סיון",
    jewishEng: "Sivan",
  },
  11: {
    jewish: "תמוז",
    jewishEng: "Tamuz",
  },
  12: {
    jewish: "אב",
    jewishEng: "Av",
  },
  13: {
    jewish: "אלול",
    jewishEng: "Elul",
  },
};

const englishMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let jewishMonths = [];
let enMonths = [];

for (const key in heMonths) {
  jewishMonths.push({
    label: heMonths[key].jewish,
    value: heMonths[key].jewish,
  });
}

englishMonths.forEach(function (value) {
  enMonths.push({
    label: value,
    value: value,
  });
});

const hebrewDate = new Hebcal(); 
const hebrewYear = hebrewDate.year;

let jewishYears = [];
let enYears = [];
for (let i = hebrewYear; i >= 5769; i--) {
  let year = convertToHebrew(i, true, true);
  let originalYear = i;
  year = year.substring(1, year.length);
  jewishYears.push({
    label: year,
    value: year,
    englishYear: originalYear,
  });
}

const currentYear = new Date().getFullYear();

for (let i = 2009; i <= currentYear; i++) {
  let year = i;
  enYears.push({
    label: year,
    value: year,
  });
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonthName = (monthNumber) => {
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Invalid month number";
  }
};

const useForm = () => {
  const { persons, authorization, writingcompetition, common } =
    useContext(StoreContext);
  const [success, setSuccess] = useState(false);

  const successMsg = persons[`success${ACTIONS.PERSONS.ADD_CHILD}`];
  const error = persons[`error${ACTIONS.PERSONS.ADD_CHILD}`];
  const loading = persons[`isLoading${ACTIONS.PERSONS.ADD_CHILD}`];
  const [isCivil, setIsCivil] = useState(true);
  const [isAfterShkiyaChecked, setIsAfterShkiyaChecked] = useState(false);
  const [addChild, setAddChild] = useState(false);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [characterImage, setCharacterImage] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      authorization.getPersons({});
    }
  }, [authorization.isAuthenticated, success]);

  const user = authorization.persons ? authorization.persons[0] : [];

  const { t } = useTranslation();
  /* labels */
  const CITY = t("forms.labels.city");
  const COUNTRY = t("forms.labels.country");
  const FIRST_NAME = t("forms.labels.first_name");
  const LAST_NAME = t("forms.labels.last_name");
  const STATE = t("forms.labels.state");
  const BOY = t("forms.labels.boy");
  const PRONUNCIATION = t("forms.labels.pronunciation");
  const GIRL = t("forms.labels.girl");
  const PERSON_ID = t("forms.labels.person_id");
  const TZIVOS_RANK_HASHEM = t("forms.labels.tzivos_rank_hashem");
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const enDay = useSelectR({
    label: "Day",
    options: enDays,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const enMonth = useSelectR({
    label: "Month",
    options: enMonths,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const enYear = useSelectR({
    label: "Year",
    options: enYears,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const jewishDay = useSelectR({
    label: "Day",
    options: jewishDays,
    value: "",
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  const jewishMonth = useSelectR({
    label: "Month",
    options: jewishMonths,
    value: "",
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  const jewishYear = useSelectR({
    label: "Year",
    options: jewishYears,
    value: "",
    validators: [
      {
        name: NAMES.REQUIRED,
        options: { message: THIS_FIELD_IS_REQUIRED },
      },
    ],
  });

  let initials = {
    details: { born_after_sunset: false },
  };

  const afterShkiyaCheckbox = useCheckbox({
    callback: ({ checked }) => {
      setIsAfterShkiyaChecked(checked);
    },
    checked: false || initials.details.born_after_sunset,
    label: `After Shkiya`,
    value: initials.details.born_after_sunset || isAfterShkiyaChecked,
  });

  useEffect(() => {
    // if (!isCivil) {
    //   afterShkiyaCheckbox.setChecked(false);
    //   setIsAfterShkiyaChecked(false);
    //   initials.details.born_after_sunset = false;
    // }

    if (isCivil) {
      if (initials.details.born_after_sunset == true) {
        setIsAfterShkiyaChecked(true);
      }
    }
  }, [isCivil]);

  const languageToggleBox = useToggle("Civil", ({ selectedValue }) => {
    // Do something with selectedValue when it changes
    if (selectedValue == "Civil") {
      setIsCivil(true);
    } else {
      setIsCivil(false);
    }
  });

  /* client */
  const lastName = useInput({
    label: LAST_NAME + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: initials.details.last_name,
  });

  const person_id = useInput({
    label: PERSON_ID,
    value: initials.details.person_id,
  });

  const rank = useInput({
    label: TZIVOS_RANK_HASHEM,
    validators: [],
    value: initials.details.rank,
  });
  /* details */
  const firstName = useInput({
    label: FIRST_NAME + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: initials.details.first_name,
  });

  const placeName = useInput({
    label: "Name of place:" + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  const mostImportantPlace = useInput({
    label: "What is the most important place in?",
    value: "",
  });

  const rulesAndTradition = useInput({
    label: "Where in the world is?",
    value: "",
  });

  const whereInWorld = useInput({
    label: "Are there any rules or traditions in?",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  const weatherLike = useInput({
    label: "What is the weather like in?",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  const unique = useInput({
    label: "What is different or unique about Cholentpot?",
    value: "",
  });

  const moreAbout = useInput({
    label: "tell us more about Cholentpot",
    value: "",
  });

  let city = useInput({
    label: CITY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: initials.details.city,
  });

  const state = useInput({
    label: STATE,
    value: initials.details.state,
  });

  const country = useInput({
    label: COUNTRY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: initials.details.country,
  });

  const pronunciation = useInput({
    label: PRONUNCIATION,
    validators: [
      // {
      //   name: NAMES.HEBREW_LATIN_SPACES,
      //   options: {
      //     message: THE_FIELD_MUST_CONTAIN_ONLY_LATIN_OR_HEBREW_CHARACTERS_AND_SPACES,
      //   },
      // },
    ],
    value: initials.details.pronunciation,
  });

  const SEX = {
    BOY: "boy",
    GIRL: "girl",
  };

  const sex = useRadio({
    options: [
      { id: "my-maor-order-form-sex-man", label: BOY, value: SEX.BOY },
      { id: "my-maor-order-form-sex-woman", label: GIRL, value: SEX.GIRL },
    ],
    value: initials.details.sex,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const GET_AROUND_OPTIONS = {
    CAR: "Car",
    BIKE: "Bike",
    BUS: "Bus",
    HORSE_AND_BUGGY: "Horse and Buggy",
    OTHER: "Other",
  };

  const getAroundRadio = useRadio({
    options: [
      {
        id: "my-maor-order-form-get-around-car",
        label: "Car",
        value: GET_AROUND_OPTIONS.CAR,
      },
      {
        id: "my-maor-order-form-get-around-bike",
        label: "Bike",
        value: GET_AROUND_OPTIONS.BIKE,
      },
      {
        id: "my-maor-order-form-get-around-bus",
        label: "Bus",
        value: GET_AROUND_OPTIONS.BUS,
      },
      {
        id: "my-maor-order-form-get-around-horse-and-buggy",
        label: "Horse and Buggy",
        value: GET_AROUND_OPTIONS.HORSE_AND_BUGGY,
      },
      {
        id: "my-maor-order-form-get-around-other",
        label: "Other",
        value: GET_AROUND_OPTIONS.OTHER,
      },
    ],
    value: null,
    // validators: [
    //   { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    // ],
  });

  const getAroundInput = useInput({
    label: "getAround",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: getAroundRadio.value != "Other" ? getAroundRadio.value || "" : "",

  });

  const GET_PLACE_OPTIONS = {
    BigCity: "Big City",
    Town: "Town",
    Shtetel: "Shtetel",
    Farm: "Farm",
    Other: "Other",
  };

  const getPlaceRadio = useRadio({
    options: [
      {
        id: "my-maor-order-form-place-big-city",
        label: GET_PLACE_OPTIONS.BigCity,
        value: GET_PLACE_OPTIONS.BigCity,
      },
      {
        id: "my-maor-order-form-place-town",
        label: GET_PLACE_OPTIONS.Town,
        value: GET_PLACE_OPTIONS.Town,
      },
      {
        id: "my-maor-order-form-place-shtetel",
        label: GET_PLACE_OPTIONS.Shtetel,
        value: GET_PLACE_OPTIONS.Shtetel,
      },
      {
        id: "my-maor-order-form-place-farm",
        label: GET_PLACE_OPTIONS.Farm,
        value: GET_PLACE_OPTIONS.Farm,
      },
      {
        id: "my-maor-order-form-place-other",
        label: "Other",
        value: GET_PLACE_OPTIONS.Other,
      },
    ],
    value: null,
  });

  const getPlaceInput = useInput({
    label: "getPlace",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: getPlaceRadio.value != "Other" ? getPlaceRadio.value || "" : "",
  });

  const GET_VIEW_OPTIONS = {
    TREES: "Trees",
    OCEAN: "Ocean",
    TALL_GREY_BUILDINGS: "Tall grey buildings",
    MOUNTAIN: "Mountain",
    OTHER: "Other",
  };

  const getViewRadio = useRadio({
    options: [
      { id: "view-trees", label: GET_VIEW_OPTIONS.TREES, value: "Trees" },
      { id: "view-ocean", label: GET_VIEW_OPTIONS.OCEAN, value: "Ocean" },
      {
        id: "view-tall-grey-buildings",
        label: GET_VIEW_OPTIONS.TALL_GREY_BUILDINGS,
        value: "Tall grey buildings",
      },
      {
        id: "view-mountain",
        label: GET_VIEW_OPTIONS.MOUNTAIN,
        value: "Mountain",
      },
      { id: "other", label: GET_VIEW_OPTIONS.OTHER, value: "Other" },
    ],
    value: null, // Adjusted for view selection
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const getViewInput = useInput({
    label: "getView",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: getViewRadio.value != "Other" ? getViewRadio.value || "" : "",
  });

  const GET_STORY_TIME_OPTIONS = {
    PAST: "Past",
    PRESENT: "Present",
    FUTURE: "Future",
  };

  const getStoryTimeRadio = useRadio({
    options: [
      { id: "time-past", label: GET_STORY_TIME_OPTIONS.PAST, value: "PAST" },
      {
        id: "time-present",
        label: GET_STORY_TIME_OPTIONS.PRESENT,
        value: "PRESENT",
      },
      {
        id: "time-future",
        label: GET_STORY_TIME_OPTIONS.FUTURE,
        value: "FUTURE",
      },
    ],
    value: "", // Adjusted for story time selection
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  //Character Feilds

  // Define the fields with validation where required (* fields)

  // My Name
  const getCharacterNameInput = useInput({
    label: "My Name",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // Mordy is a (Boy/Girl)
  const getCharacterGenderRadio = useRadio({
    options: [
      { id: "gender-girl", label: "Girl", value: "Girl" },
      { id: "gender-boy", label: "Boy", value: "Boy" },
    ],
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  // How old is Mordy?
  const getCharacterAgeInput = useInput({
    label: "How old is Mordy?",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
      { name: NAMES.DIGITS, options: { message: "Only Number Is Allowed" } },
    ],
    value: "",
  });

  // Define the constants for Mordy's height options
  const GET_CHARACTER_HEIGHT_OPTIONS = {
    TALL: "Tall",
    AVERAGE: "Average",
    SHORT: "Short",
    Other: "Other",
  };

  const getCharacterHeightRadio = useRadio({
    options: [
      {
        id: "character-height-tall",
        label: GET_CHARACTER_HEIGHT_OPTIONS.TALL,
        value: "TALL",
      },
      {
        id: "character-height-average",
        label: GET_CHARACTER_HEIGHT_OPTIONS.AVERAGE,
        value: "AVERAGE",
      },
      {
        id: "character-height-short",
        label: GET_CHARACTER_HEIGHT_OPTIONS.SHORT,
        value: "SHORT",
      },
      {
        id: "character-height-other",
        label: GET_CHARACTER_HEIGHT_OPTIONS.Other,
        value: "Other",
      },
    ],
    value: null, // Adjusted for height selection
  });

  const getCharacterHeightInput = useInput({
    label: "Character Height",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: getCharacterHeightRadio.value != "Other" ? getCharacterHeightRadio.value || "" : "",
  });

  // Where does Mordy live?
  const getCharacterLivingPlaceInput = useInput({
    label: "Where does Mordy live?",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // Mordy’s favorite mitzvah
  const getCharacterFavoriteMitzvahInput = useInput({
    label: "Mordy’s Favorite Mitzvah",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // Mordy’s favorite food
  const getCharacterFavoriteFoodInput = useInput({
    label: "Mordy’s Favorite Food",
    value: "",
  });

  // Mordy does not like when
  const getCharacterDislikesInput = useInput({
    label: "Mordy does not like when",
    value: "", // Not required
  });

  // Mordy’s favorite activity
  const getCharacterFavoriteActivityInput = useInput({
    label: "Mordy’s Favorite Activity",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // Mordy’s favorite Yom Tov and why
  const getCharacterFavoriteYomTovInput = useInput({
    label: "Mordy’s Favorite Yom Tov and why",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // What makes Mordy happy?
  const getCharacterHappinessInput = useInput({
    label: "What makes Mordy happy?",
    value: "",
  });

  // What is Mordy very good at?
  const getCharacterGoodAtInput = useInput({
    label: "What is Mordy very good at?",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // What is Mordy wearing?
  const getCharacterWearingInput = useInput({
    label: "What is Mordy wearing?",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  // Fun fact about Mordy
  const getCharacterFunFactInput = useInput({
    label: "Fun fact about Mordy",
    value: "", // Not required
  });

  // Describe Mordy’s family
  const getCharacterFamilyInput = useInput({
    label: "Describe Mordy’s family",
    value: "",
  });

  // What else can you tell us about Mordy?
  const getCharacterAdditionalInfoInput = useInput({
    label: "What else can you tell us about Mordy?",
    value: "", // Not required
  });

  //Character Form Location
  const characterFormLocation = useInput({
    label:  "address location",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });


  const characterFormCity = useInput({
    label: CITY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  const characterFormState = useInput({
    label: STATE,
    value: "",
  });

  const characterFormCountry = useInput({
    label: COUNTRY + " *",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
    value: "",
  });

  let isEnglishDate = false;

  isEnglishDate =
    enDay.errors.length ||
    enMonth.errors.length ||
    enYear.errors.length ||
    enDay.value.value == undefined ||
    enMonth.value.value == undefined ||
    enYear.value.value == undefined;

  let isJewishDate = false;
  isJewishDate =
    jewishDay.errors.length ||
    jewishMonth.errors.length ||
    jewishYear.errors.length ||
    jewishDay.value.value == undefined ||
    jewishMonth.value.value == undefined ||
    jewishYear.value.value == undefined;

  // For covert english to Jewsih for Frontend
  let englishDate = `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}`;
  const jewishDate = formatJewishDate(
    new Date(englishDate),
    isAfterShkiyaChecked
  );
  const englishToJewishDate = convertJewishEngToJewish(jewishDate);

  let jewishToEnglishDate;
  let jewishToEnglishMonth;
  let jewishToEnglishYear;

  let englishToJewishDateArray = englishToJewishDate.split(" ").reverse();
  if (englishToJewishDateArray.length > 3) {
    jewishToEnglishDate =
      englishToJewishDateArray[englishToJewishDateArray.length - 1];
    jewishToEnglishMonth =
      englishToJewishDateArray[2] + " " + englishToJewishDateArray[1];
    jewishToEnglishYear = englishToJewishDateArray[0];
  } else {
    jewishToEnglishDate =
      englishToJewishDateArray[englishToJewishDateArray.length - 1];
    jewishToEnglishMonth = englishToJewishDateArray[1];
    jewishToEnglishYear = englishToJewishDateArray[0];
  }

  useEffect(() => {
    if (!isCivil) {
      jewishDay.onChange({
        label: jewishToEnglishDate,
        value: jewishToEnglishDate,
      });
      jewishMonth.onChange({
        label: jewishToEnglishMonth,
        value: jewishToEnglishMonth,
      });
      jewishYear.onChange({
        label: jewishToEnglishYear,
        value: jewishToEnglishYear,
      });
    }
  }, [isCivil]);

  // Enlish Date For backend Payload
  const englishDateString = new Date(
    `${enDay.value.value} ${enMonth.value.value} ${enYear.value.value}`
  );
  const englishDateDay = englishDateString.getDate();
  const englishDateMonth = englishDateString.getMonth() + 1;
  const englishDateYear = englishDateString.getFullYear();
  const englishFullDate = `${englishDateDay} ${englishDateMonth} ${englishDateYear}`;

  // Convert jewish to jewishEnglish language
  let yearData = "";
  Object.values(jewishYears).find((year) => {
    if (year.value === jewishYear.value.value) {
      yearData = year.englishYear;
    }
  });

  let dayData = "";
  Object.values(jewishDays).find((day) => {
    if (day.value === jewishDay.value.value) {
      dayData = day.englishDay;
    }
  });

  const jewEngLishMonth = getJewishEnglishMonth(jewishMonth.value.value);
  const jewEngLishYear = yearData;
  const jewEngLishDay = dayData;
  const jewishFullDate = `${jewEngLishDay} ${jewEngLishMonth} ${jewEngLishYear}`;

  // Converte JewishEnglish to English
  let heDate = new Hebcal.HDate(jewishFullDate);
  let prevDate = heDate.prev().greg();
  const gregorianDate = heDate.greg();

  const prevMonth = prevDate.getMonth() + 1;
  const prevYear = prevDate.getFullYear();
  const prevDay = prevDate.getDate();

  const gregorianMonth = gregorianDate.getMonth() + 1;
  const gregorianYear = gregorianDate.getFullYear();
  const gregorianDay = gregorianDate.getDate();

  // For backend payload
  const gregorianDateFullDate = `${gregorianDay} ${gregorianMonth} ${gregorianYear}`;
  // For FrontEnd
  const gregorianDateText = `${
    isAfterShkiyaChecked ? prevMonth : gregorianMonth
  }/${isAfterShkiyaChecked ? prevDay : gregorianDay}/${
    isAfterShkiyaChecked ? prevYear : gregorianYear
  }`;

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!gregorianDay) {
        enDay.onChange({ label: "", value: "" }, false);
      } else {
        enDay.onChange({
          label: isAfterShkiyaChecked ? prevDay : gregorianDay,
          value: isAfterShkiyaChecked ? prevDay : gregorianDay,
        });
      }
      if (!gregorianMonth) {
        enMonth.onChange({ label: "", value: "" }, false);
      } else {
        enMonth.onChange({
          label: isAfterShkiyaChecked
            ? getMonthName(prevMonth)
            : getMonthName(gregorianMonth),
          value: isAfterShkiyaChecked
            ? getMonthName(prevMonth)
            : getMonthName(gregorianMonth),
        });
      }
      if (!gregorianYear) {
        enYear.onChange({ label: "", value: "" }, false);
      } else {
        enYear.onChange({
          label: isAfterShkiyaChecked ? prevYear : gregorianYear,
          value: isAfterShkiyaChecked ? prevYear : gregorianYear,
        });
      }
    } else {
      // Set the flag to false after initial render
      isInitialRender.current = false;
    }
  }, [isCivil]);

  const triggerValidationsPersonal = () => {
    firstName.touch();
    lastName.touch();
    city.touch();
    country.touch();
    sex.touch();
    enMonth.touch();
    enYear.touch();
    enDay.touch();
  };

  const triggerValidationsWriting = () => {
    firstName.touch();
    placeName.touch();
    getViewInput.touch();
    getPlaceInput.touch();
    whereInWorld.touch();
    weatherLike.touch();
    getStoryTimeRadio.touch();
    unique.touch();
    moreAbout.touch();
  };

  const triggerValidationsCharacterLocation = () => { 
    characterFormLocation.touch();
  }

  const triggerValidationsCharacter = () => {
    getCharacterNameInput.touch();
    getCharacterGenderRadio.touch();
    getCharacterAgeInput.touch();
    getCharacterHeightInput.touch();
    getCharacterLivingPlaceInput.touch();
    getCharacterFavoriteMitzvahInput.touch();
    getCharacterFavoriteActivityInput.touch();
    getCharacterFavoriteYomTovInput.touch();
    getCharacterGoodAtInput.touch();
    getCharacterWearingInput.touch();
  };

  const submitChild = () => {
    persons[`${ACTIONS.PERSONS.ADD_CHILD}`]({
      first_name: firstName.value,
      last_name: lastName.value,
      city: city.value,
      state: state.value,
      country: country.value,
      pronunciation: pronunciation.errors.length ? "" : pronunciation.value,
      rank: rank.errors.length ? "" : rank.value,
      person_id: person_id.errors.length ? "" : person_id.value,

      sex: sex.value,
      date_language: isCivil ? "english" : "hebrew",
      born_after_sunset: isCivil ? isAfterShkiyaChecked : false,
      hebrew_date: isCivil
        ? englishToJewishDate
        : `${jewishDay.value.value} ${jewishMonth.value.value} ${jewishYear.value.value}`,
      date: isCivil
        ? {
            en: englishFullDate,
            he: jewishDate,
          }
        : {
            en: gregorianDateFullDate,
            he: jewishFullDate,
          },
    });
  };

  const characters = [];

  // Prepare characters array
  if (getCharacterNameInput.value) {
    let character = {
      name: getCharacterNameInput.value,
      age: getCharacterAgeInput.value,
      gender: getCharacterGenderRadio.value,
      height: getCharacterHeightInput.value,
      livingPlace: getCharacterLivingPlaceInput.value,
      favorite_mitzvah: getCharacterFavoriteMitzvahInput.value,
      favorite_food: getCharacterFavoriteFoodInput.value,
      dislikes: getCharacterDislikesInput.value,
      favorite_activity: getCharacterFavoriteActivityInput.value,
      favorite_yom_tov: getCharacterFavoriteYomTovInput.value,
      happiness: getCharacterHappinessInput.value,
      good_at: getCharacterGoodAtInput.value,
      wearing: getCharacterWearingInput.value,
      fun_fact: getCharacterFunFactInput.value,
      family: getCharacterFamilyInput.value,
      additional_info: getCharacterAdditionalInfoInput.value,
    };
    characters.push(character);
  }

  const submitStory = ({ submissionDataStatus = false, draftStatus = false, characterSubmitStatus = false, onSucess = () => {

  } } = {}) => {
    const submissionData = common.get(CHARACTER_SUBMISSION_KEY);
  
    const exFormData = jsonToFormData({
      data: {
        ...submissionData,
      },
    });
  
    // Adjust your API call to handle FormData
    writingcompetition[ACTIONS.WRITING_COMPETITON.SUBMIT]({
      exFormData,
      submissionDataStatus,
      draftStatus,
      characterSubmitStatus,
      onSucess
    });
  };
  

  const submitCharacter = () => {
    let character = {
      name: getCharacterNameInput.value,
      age: getCharacterAgeInput.value,
      gender: getCharacterGenderRadio.value,
      height: getCharacterHeightInput.value,
      livingPlace: getCharacterLivingPlaceInput.value,
      favorite_mitzvah: getCharacterFavoriteMitzvahInput.value,
      favorite_food: getCharacterFavoriteFoodInput.value,
      dislikes: getCharacterDislikesInput.value,
      favorite_activity: getCharacterFavoriteActivityInput.value,
      favorite_yom_tov: getCharacterFavoriteYomTovInput.value,
      happiness: getCharacterHappinessInput.value,
      good_at: getCharacterGoodAtInput.value,
      wearing: getCharacterWearingInput.value,
      fun_fact: getCharacterFunFactInput.value,
      family: getCharacterFamilyInput.value,
      additional_info: getCharacterAdditionalInfoInput.value,
    };

    console.log(character);
  };

  useEffect(() => {
    setSuccess(persons[`success${ACTIONS.PERSONS.ADD_CHILD}`]);
    persons[`setSuccess`](ACTIONS.PERSONS.ADD_CHILD, false);
  }, [
    persons[`success${ACTIONS.PERSONS.ADD_CHILD}`],
    persons[`isLoading${ACTIONS.PERSONS.ADD_CHILD}`],
    persons,
  ]);

  const isNotValidPicture = characterImage;

  const isNotValidChildren = selectedChildren.length < 0;

  const isNotValidCharacterLocation = !characterFormLocation.value;

  const isNotValidPlaceSettings =
    !placeName.value ||
    !getViewInput.value ||
    !getPlaceInput.value ||
    !whereInWorld.value ||
    !weatherLike.value ||
    getStoryTimeRadio.errors.length ||
    getStoryTimeRadio.value === "";

  const isNotValidCharacterSettings =
    !getCharacterNameInput.value ||
    !getCharacterGenderRadio.value ||
    !getCharacterAgeInput.value ||
    !getCharacterLivingPlaceInput.value ||
    !getCharacterFavoriteMitzvahInput.value ||
    !getCharacterFavoriteActivityInput.value ||
    !getCharacterFavoriteYomTovInput.value ||
    !getCharacterGoodAtInput.value ||
    !getCharacterWearingInput.value ||
    !getCharacterHeightInput.value ||
    getCharacterGenderRadio.errors.length;

  const isNotValid =
    !country.value ||
    ((country.value.toLowerCase() == "usa" ||
      country.value.toLowerCase() == "us") &&
      !state.value) ||
    !city.value ||
    !firstName.value ||
    !lastName.value ||
    sex.errors.length ||
    sex.value == "" ||
    (isCivil &&
      (enDay.errors.length ||
        enMonth.errors.length ||
        enYear.errors.length ||
        enDay.value.value == undefined ||
        enMonth.value.value == undefined ||
        enYear.value.value == undefined)) ||
    (!isCivil &&
      (jewishDay.errors.length ||
        jewishMonth.errors.length ||
        jewishYear.errors.length ||
        jewishDay.errors.length == undefined ||
        jewishMonth.errors.length == undefined ||
        jewishYear.errors.length == undefined));

  return {
    city: city,
    country: country,
    firstName: firstName,
    lastName: lastName,
    state: state,
    success: success,
    submitChild: submitChild,
    isNotValid: isNotValid,
    successMsg: successMsg,
    loading: loading,
    error: error,
    user: user,
    jewishDay: jewishDay,
    jewishMonth: jewishMonth,
    jewishYear: jewishYear,
    gregorianDateText: gregorianDateText,
    enDay: enDay,
    enMonth: enMonth,
    enYear: enYear,
    englishToJewishDate: englishToJewishDate,
    jewishDate: jewishDate,
    jewishFullDate: jewishFullDate,
    isEnglishDate: isEnglishDate,
    languageToggleBox: languageToggleBox,
    afterShkiyaCheckbox: afterShkiyaCheckbox,
    sex: sex,
    isAfterShkiyaChecked: isAfterShkiyaChecked,
    setIsAfterShkiyaChecked: setIsAfterShkiyaChecked,
    isJewishDate: isJewishDate,
    setAddChild: setAddChild,
    addChild: addChild,
    pronunciation,
    rank,
    person_id,
    triggerValidationsPersonal,
    placeName,
    mostImportantPlace,
    rulesAndTradition,
    whereInWorld,
    weatherLike,
    unique,
    moreAbout,
    getAroundRadio,
    getAroundInput,
    getPlaceInput,
    getPlaceRadio,
    triggerValidationsWriting,
    getViewInput,
    getViewRadio,
    getStoryTimeRadio,
    isNotValidPlaceSettings,
    selectedChildren,
    setSelectedChildren,
    characterImage,
    setCharacterImage,
    uploadError,
    setUploadError,
    getCharacterNameInput,
    getCharacterGenderRadio,
    getCharacterAgeInput,
    getCharacterHeightRadio,
    getCharacterHeightInput,
    getCharacterLivingPlaceInput,
    getCharacterFavoriteMitzvahInput,
    getCharacterFavoriteFoodInput,
    getCharacterDislikesInput,
    getCharacterFavoriteActivityInput,
    getCharacterFavoriteYomTovInput,
    getCharacterHappinessInput,
    getCharacterGoodAtInput,
    getCharacterWearingInput,
    getCharacterFunFactInput,
    getCharacterFamilyInput,
    getCharacterAdditionalInfoInput,
    isNotValidCharacterSettings,
    triggerValidationsCharacter,
    submitCharacter,
    submitStory,
    isNotValidPicture,
    isNotValidChildren,
    characterFormCountry,
    characterFormState,
    characterFormCity,
    isNotValidCharacterLocation,
    triggerValidationsCharacterLocation,
    characterFormLocation
  };
};

export default useForm;
