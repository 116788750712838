import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import Button from "../../../../atoms/Button";
import Checkbox from "../../../../atoms/Checkbox";
import Loader from "../../../../atoms/Loader";
import { StoreContext } from "../../../../../contexts";
import { ACTIONS } from "../../../../../store";
import { useCheckbox } from "../../../../../hooks";
import classes from "./Submit.module.scss";
import cs from "classnames";
import { payMethodCreditsValidation } from "../../../../../utilities/helpers";

const IDENTIFIERS = {
  BUTTON: "braintree-button",
  CONTAINER: "braintree-container",
};

const Submit = observer(({ pledgeDonation, error }) => {
  const { t } = useTranslation();
  const { cart, checkout } = useContext(StoreContext);
  const confirmation = useCheckbox();
  const isDisabled =
    cart[`isLoading${ACTIONS.CART.GET}`] ||
    cart[`error${ACTIONS.CART.GET}`] ||
    checkout[`isLoading${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`] ||
    checkout[`error${ACTIONS.CHECKOUT.CREATE_TRANSACTION}`] ||
    checkout.hasNoTransition ||
    checkout.hasNoToken ||
    !confirmation.checked;
  const isLoading = checkout[`isLoading${ACTIONS.CHECKOUT.SALE}`];

  console.log(isDisabled);

  return (
    <div className={classes.container}>
      <div className={cs(classes.checkbox, classes.hide)}>
        <Checkbox
          value={confirmation.value}
          color={pledgeDonation ? "purpleCheckbox" : "primary"}
          onChange={confirmation.onChange}
        >
          {t("pages.checkout.texts.6")}
        </Checkbox>
      </div>

      {pledgeDonation && (
        <div className={cs("mt-04")}>
          <p className={cs(classes.newLink)}>
            If this page doesn&apos;t work,
            <span className={cs(classes.orangeColor)}>
              {" "}
              <a
                href="https://mymaor.org/form/view.php?id=45332"
                target="_blank"
                rel="noreferrer noopener"
              >
                {" "}
                Click here{" "}
              </a>{" "}
            </span>{" "}
            to use alternative page.
          </p>
        </div>
      )}

      <div className={classes.button}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {error ? (
              <Button
                color={pledgeDonation ? "purple" : "primary"}
                onClick={() => window.location.reload()}
              >
                {t("pages.checkout.labels.10")}
              </Button>
            ) : (
              <Button
                id={IDENTIFIERS.BUTTON}
                color={pledgeDonation ? "purple" : "primary"}
                onClick={payMethodCreditsValidation}
              >
                {t("pages.checkout.labels.2")}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default Submit;
