const ACTIONS = {
  PASSWORD_RECOVERY_REQUEST: "PasswordRecoveryRequest",
  PASSWORD_RECOVERY_UPDATE: "PasswordRecoveryUpdate",
  RESIGN_IN: "ResignIn",
  SEND_QUESTIONNAIRE: "SendQuestionnaire",
  SIGN_IN: "SignIn",
  SIGN_UP: "SignUp",
  VERIFY_EMAIL: "VerifyEmail",
  AUTHENTICATE_PASSWORD: "AuthenticatePassword",
  GET_PERSONS: "GetPersons",
  GET_LATEST_GOLD_PARTNERS: "getLatestGoldPartners",
  GET_ADDRESS: "GetAddress",
  DELETE_ADDRESS: "deleteAddress",
  ADD_ADDRESS: "AddAddress",
  EDIT_ADDRESS: "EditAddress",
  SET_DEFAULT_ADDRESS: "SetDefaultAddress",
  RESEND_EMAIL_VERIFICATION: "ResendEmailVerification",
};

export { ACTIONS };
