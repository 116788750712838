/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef, useEffect } from "react";
import PageTitle from "../../atoms/PageTitle";
import { APPLICATION_ROUTES, COOKIES, PAGE_TITLES } from "../../../constants";
import Layout from "../../templates/Layout";
import Loader from "../../atoms/Loader";
import cs from "classnames";
import classes from "./WritingCompetitionVideoForm.module.scss";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import uploadCharacter from "../../../assets/images/upload-character.png";
import jsonToFormData from "../../../utilities/helpers/jsonToFromData";
import { Loader2 } from "lucide-react";
import { observer } from "mobx-react-lite";
import WritingCompetitionLayout from "../../templates/WritingCompetitionLayout";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { formatChildNames } from "../WritingCompetition/WritingCompetition";
import Checkbox from "../../atoms/Checkbox";
import { useCheckbox, useRadio } from "../../../hooks";
import { NAMES } from "../../../utilities/validation";
import { useTranslation } from "react-i18next";
import RadioDotsCharacter from "../../atoms/RadioDotsCharacter";
import Cookies from "../../../utilities/cookies";

const BYPASS_KEY = "eJwBMADP";

const WritingCompetitionVideoForm = observer(() => {
  const { authorization, writingcompetition, common } =
    useContext(StoreContext);
  const getAction = ACTIONS.WRITING_COMPETITON.GET_SUBMISSION;
  const history = useHistory();
  // State for uploaded files and validation errors
  const [scriptFile, setScriptFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [errors, setErrors] = useState({ script: "", video: "", checkbox: "" });

  // State for filenames
  const [scriptFileName, setScriptFileName] = useState("");
  const [videoFileName, setVideoFileName] = useState("");

  const { t } = useTranslation();
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const [toichenButtons, setToichenButtons] = useState([]);
  const url = new URL(window.location.href);

  useEffect(() => {
    const buttons = [];
    writingcompetition?.data?.stages?.['Script Submission']?.toichen_buttons?.forEach((button) => {
      buttons.push({
        id: button.text,
        label: button.text,
        value: button.text,
      });
    });
    setToichenButtons(buttons);
  }, [writingcompetition?.submissionData, writingcompetition.data]);

  const getToichenButtons = useRadio({
    options: toichenButtons,
    value: "",
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  const isNiceSelected = useCheckbox({
    checked: false,
    label: `My characters talk nicely to each other.`,
    value: false,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });
  const isEidileSelected = useCheckbox({
    checked: false,
    label: `My characters are tzniusdik and eidile.`,
    value: false,
    validators: [
      { name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } },
    ],
  });

  // Refs for the hidden file inputs
  const scriptInputRef = useRef(null);
  const videoInputRef = useRef(null);

  // const characterData = common.get("currentCharacter");

  const childNames = formatChildNames(
    writingcompetition?.submissionData?.selected_childs_object
  );

  // Validate file type and size
  // Validate file type
  const validateFile = (file, type) => {
    const allowedTypes = {
      script: [
        "application/vnd.oasis.opendocument.text",
        "text/html",
        "application/xml",
        "application/json",
        "text/csv",
        "text/markdown",
        "application/x-tex",
        "application/pdf", // PDF
        "application/msword", // Word Document (.doc)
        "image/jpeg", // JPEG Image
        "image/png", // PNG Image
        "image/jpg", // JPG Image
        "application/rtf", // Rich Text Format
        "text/plain", // Plain Text File
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      video: [
        "video/mp4", // MP4 Video
        "video/mov", // MOV Video
        "video/avi", // AVI Video
        "video/webm", // WebM Video
        "video/mkv", // MKV Video
        "video/quicktime", // QuickTime Video
      ],
    };

    if (!file) return "File is required.";

    if (type == "script") {
      if (!allowedTypes[type].includes(file.type)) {
        return `Invalid file type. Allowed types: ${allowedTypes[type].join(
          ", "
        )}`;
      }
    } else {
      if (!file.type.startsWith("video/")) {
        return `Invalid file type. Allowed types: ${allowedTypes[type].join(
          ", "
        )}`;
      }
    }

    return ""; // Remove the size validation
  };

  // Handlers for file uploads
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    const error = validateFile(file, type);

    if (error) {
      setErrors((prev) => ({ ...prev, [type]: error }));
    } else {
      setErrors((prev) => ({ ...prev, [type]: "" }));
      if (type === "script") {
        setScriptFile(file);
        setScriptFileName(file.name); // Set the filename for the script
      }
      if (type === "video") {
        setVideoFile(file);
        setVideoFileName(file.name); // Set the filename for the video
      }
    }
  };

  const submitScript = (
    scriptFile,
    videoFile,
    isEidile,
    isNice,
    toichen_topic
  ) => {
    const exFormData = jsonToFormData({
      data: {
        script: scriptFile,
        video: videoFile,
        is_eidile: isEidile,
        is_nice: isNice,
        status: "submitted",
        toichen_topic: toichen_topic,
      },
    });

    writingcompetition[`${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`](
      exFormData
    );
  };

  const handleSubmit = () => {
    getToichenButtons.touch();
    const areFilesUploaded = scriptFile && videoFile;
    const areCheckboxesChecked =
      isNiceSelected.checked && isEidileSelected.checked;

    let errorMessages = [];

    if (!areFilesUploaded) {
      errorMessages.push("Please upload files");
    }
    if (!areCheckboxesChecked) {
      errorMessages.push("Please check off all the boxes");
    }
    if (!getToichenButtons.value) {
      errorMessages.push("Please select one Toichen topic");
    }

    const errorMessage = errorMessages.join(". ");

    setErrors(() => ({
      script: !scriptFile ? "Please upload script file." : "",
      video: !videoFile ? "Please upload Video file." : "",
      checkbox: errorMessage,
      toichenButtonsError: errorMessage,
    }));

    if (areFilesUploaded && areCheckboxesChecked && getToichenButtons.value) {
      setErrors(() => ({
        script: "",
        video: "",
        checkbox: "",
        toichenButtonsError: "",
      }));

      submitScript(
        scriptFile,
        videoFile,
        isEidileSelected.checked,
        isNiceSelected.checked,
        getToichenButtons.value
      );
    }
  };

  useEffect(() => {
    if (
      writingcompetition[`success${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`]
    ) {
      history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION_SUCCESS);
    }
  }, [
    writingcompetition[`success${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`],
  ]);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      writingcompetition[`${ACTIONS.WRITING_COMPETITON.GET_SUBMISSION}`]();
      writingcompetition[`${ACTIONS.WRITING_COMPETITON.GET}`](authorization.isAuthenticated);
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    const jwt = Cookies.get(COOKIES.AUTHORIZATION);
    if (jwt?.jwt) {
      if (writingcompetition?.submissionData) {
        const bypass = url.searchParams.get(BYPASS_KEY) === "1";
        const isAccepted = writingcompetition.submissionData?.character_submission_status === "accepted";
        if (bypass && !isAccepted) {
          history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION);
        } else if (!bypass) {
          history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION);
        }
      }
    } else {
      history.push(
        `${APPLICATION_ROUTES.SIGN_UP}?redirect=${APPLICATION_ROUTES.WRITING_COMPETITON_VIDEO_FORM}?${BYPASS_KEY}=1`
      );
    }
  }, [writingcompetition.submissionData, authorization.isAuthenticated]);

  return (
    <>
      <PageTitle value={PAGE_TITLES.WRITING_COMPETITON_VIDEO_FORM} />
      <Layout logotype="maor">
        <WritingCompetitionLayout>
          <>
            {writingcompetition[`isLoading${getAction}`] ||
            authorization.isLoadingSignIn ||
            authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ||
            !writingcompetition?.submissionData ? (
              <Loader />
            ) : (
              <div className={cs(classes.mainContainer)}>
                <h2 className={cs(classes.heading, classes.grandstanderFamily)}>
                  Welcome Back!
                </h2>
                <h2
                  className={cs(classes.redHeading, classes.grandstanderFamily)}
                >
                  {childNames || ""}
                </h2>

                {toichenButtons.length > 0 && (
                  <div className={cs(classes.toichenOptions)}>
                    <h4 className={cs(classes.grandstanderFamily)}>
                      My Toichen topic is:
                    </h4>
                    <div className={cs(classes.optionsContainer)}>
                      <RadioDotsCharacter
                        vertical
                        items={getToichenButtons.options}
                        value={getToichenButtons.value}
                        onChange={getToichenButtons.onChange}
                        error={getToichenButtons.error}
                        additionalClass="scriptFormContainer"
                      />
                    </div>
                  </div>
                )}

                <div className={cs(classes.buttonContainer)}>
                  {/* Upload Script File */}
                  <div>
                    <div
                      className={cs(
                        classes.uploadButton,
                        errors.script && classes.borderRed
                      )}
                      onClick={() => scriptInputRef.current.click()}
                    >
                      <input
                        ref={scriptInputRef}
                        type="file"
                        accept=".pdf,.doc,.docx,.jpg,.png"
                        onChange={(e) => handleFileChange(e, "script")}
                        style={{ display: "none" }}
                      />
                      <div className={cs(classes.uploadIcon)}>
                        <img src={uploadCharacter} alt="uploadCharacter" />
                      </div>
                      <div className={cs(classes.uploadButtonText)}>
                        <h2 className={cs(classes.grandstanderFamily)}>
                          Upload Script File
                        </h2>
                        <p>(PDF, Word Document, or Photo)</p>
                      </div>
                    </div>
                    {scriptFileName && (
                      <p
                        className={cs(
                          classes.fileName,
                          classes.grandstanderFamily
                        )}
                      >
                        Uploaded: {scriptFileName}
                      </p>
                    )}
                  </div>

                  {/* Upload Video File */}
                  <div>
                    <div
                      className={cs(
                        classes.uploadButton,
                        errors.video && classes.borderRed
                      )}
                      onClick={() => videoInputRef.current.click()}
                    >
                      <input
                        ref={videoInputRef}
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleFileChange(e, "video")}
                        style={{ display: "none" }}
                      />
                      <div className={cs(classes.uploadIcon)}>
                        <img src={uploadCharacter} alt="uploadCharacter" />
                      </div>
                      <div className={cs(classes.uploadButtonText)}>
                        <h2
                          className={cs(
                            classes.heading,
                            classes.grandstanderFamily
                          )}
                        >
                          Upload Video File
                        </h2>
                      </div>
                    </div>
                    {videoFileName && (
                      <p
                        className={cs(
                          classes.fileName,
                          classes.grandstanderFamily
                        )}
                      >
                        Uploaded: {videoFileName}
                      </p>
                    )}
                  </div>
                </div>

                <div className={cs(classes.checkboxContainer)}>
                  <Checkbox
                    color={
                      !errors.checkbox || isNiceSelected.checked
                        ? "lightYellowCheckbox"
                        : "redCheckbox"
                    }
                    {...isNiceSelected}
                  >
                    {isNiceSelected.label}
                  </Checkbox>
                  <Checkbox
                    color={
                      !errors.checkbox || isEidileSelected.checked
                        ? "lightYellowCheckbox"
                        : "redCheckbox"
                    }
                    {...isEidileSelected}
                  >
                    {isEidileSelected.label}
                  </Checkbox>
                </div>

                <p
                  className={cs(
                    classes.submitError,
                    classes.grandstanderFamily
                  )}
                >
                  {errors.checkbox
                    ? errors.checkbox
                    : errors.script && errors.video
                    ? "Please upload the files."
                    : errors.script
                    ? errors.script
                    : errors.video}
                </p>

                {writingcompetition[
                  `isLoading${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`
                ] && (
                  <p
                    className={cs(classes.grandstanderFamily, classes.lightRed)}
                  >
                    We are uploading your file. DO NOT close this window.
                  </p>
                )}

                <button
                  disabled={
                    writingcompetition[
                      `isLoading${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`
                    ]
                  }
                  className={cs(
                    classes.submitScriptButton,
                    classes.grandstanderFamily
                  )}
                  onClick={handleSubmit}
                >
                  {writingcompetition[
                    `isLoading${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`
                  ] ? (
                    <Loader2 className={cs(classes.loader)} />
                  ) : (
                    "Submit my Script "
                  )}
                </button>
              </div>
            )}
          </>
        </WritingCompetitionLayout>
      </Layout>
    </>
  );
});

export default WritingCompetitionVideoForm;
